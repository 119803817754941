import { useState, useEffect } from "react";
import FormTeamUitjePanel from "../../../Components/Forms/FormTeamUitjePanel";
import { useBackupContext } from "Context/BackupContext"

import { addTeamuitjeAction, updateTeamuitjeAction, validateTeamuitjeForm, createTeamuitjeFormData } from "../Actions/Teamuitje";

export default function FormTeamUitjeContainer({ selectedDay, event, reload, onClose }) {

	const { backupMode } = useBackupContext();
    const [teamUitjeFormData, setTeamUitjeFormData] = useState({ amountOfParticipants: 12, lunch: false, sendEmails: false });
    const [teamUitjeErrors, setTeamUitjeErrors] = useState({});
	const [loading, setLoading] = useState(false);

    useEffect(() => {
		setTeamUitjeFormData(createTeamuitjeFormData(event));
    }, [event])

	const updateTeamuitje = async (teamUitjeFormData) => {
		setLoading(true);
		try {
			updateTeamuitjeAction(teamUitjeFormData, event);	
			setLoading(false);
			onClose({ message: "Teamuitje is aangepast" });
			reload();
		} catch (err) {
			setLoading(false);
			console.log(err);
		}
	}

	const addTeamuitje = async (teamUitjeFormData) => {
		setLoading(true);
		try {
			addTeamuitjeAction(teamUitjeFormData, selectedDay);
            setLoading(false);
			onClose({ message: "Teamuitje is toegevoegd" });
			reload();
		} catch (err) {
			setLoading(false);
			console.log(err);
		}
	}

	const validate = (teamUitjeFormData) => {
		let { errors } = validateTeamuitjeForm(teamUitjeFormData);
		setTeamUitjeErrors(errors);
		return Object.values(errors).some((error) => error);
	}

	const saveTeamuitje = async () => {

		if(backupMode) {
			console.log("restore backup");
			return;
		}

		if(!validate(teamUitjeFormData)) {

			if(event?.data?.mainRequest?.$id) {
				return updateTeamuitje(teamUitjeFormData);
			} else {
				return addTeamuitje(teamUitjeFormData);
			}
		}
	}


    return <FormTeamUitjePanel 
		backupMode={backupMode}
		loading={loading}
		errors={teamUitjeErrors} 
		selectedDay={selectedDay} 
		unavailableDayParts={event?.data?.unavailableDayParts || []} 
		onSave={saveTeamuitje} 
		teamUitje={teamUitjeFormData} setTeamUitje={setTeamUitjeFormData} />


}
