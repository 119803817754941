
import api from "api/api";
import { Server } from "Utils/config";
import { getData } from "Utils/form";
import { cleanProperties } from "Utils/object";

const teamUitjeFormFields = [
	'date',
	'daypart', 
	'amountOfParticipants', 
	'lunch',
	'firstName', 
	'lastName', 
	'emailAddress', 
	'phoneNumber', 
	'organizationName',
	'department',
	'organizationAddress',
	'organizationPostalCode',
	'organizationCity',
	'comments',
    "status",
	'sendEmails'
]

export async function updateTeamuitjeAction(teamUitjeFormData, event) {
	let mainRequestToSave = { ...getData(teamUitjeFormData, teamUitjeFormFields, [], ['daypart', 'date', 'status']) };
	delete mainRequestToSave.$$type;
	await api.updateDocument(Server.databaseID, Server.collections['TeamuitjeRequest'], event.data.mainRequest.$id, mainRequestToSave );
	let detailsRequestToSave = { ...getData(teamUitjeFormData, teamUitjeFormFields, ['daypart', 'date', 'status']) };
	if(event.data.detailsRequest.$id) {
		console.log("detailsRequestToSave", detailsRequestToSave);
		await api.updateDocument(Server.databaseID, Server.collections['TeamuitjeRequestDetails'], event.data.detailsRequest.$id, detailsRequestToSave);
	} else {
		console.log("detailsRequestToSave (creating)", detailsRequestToSave);
		detailsRequestToSave.requestId = mainRequestToSave.$id;
		await api.createDocument(Server.databaseID, Server.collections['TeamuitjeRequestDetails'], detailsRequestToSave);
	}
	return true;
}

export async function addTeamuitjeAction(teamUitjeFormData, selectedDay) {
	let mainRequestToSave = { ...getData(teamUitjeFormData, teamUitjeFormFields, [], ['daypart', 'date', 'status']) };
	mainRequestToSave.date = selectedDay;
	mainRequestToSave.status = mainRequestToSave.status ?? 'pending';
	let request = await api.createDocument(Server.databaseID, Server.collections['TeamuitjeRequest'], mainRequestToSave);
	let requestId = request.$id;
	let detailsRequestToSave = { ...getData(teamUitjeFormData, teamUitjeFormFields, ['daypart', 'date', 'status']) };
	let createData = { ...detailsRequestToSave, requestId: requestId };
	await api.createDocument(Server.databaseID, Server.collections['TeamuitjeRequestDetails'], createData);
	return true;
}

export async function restoreTeamuitjeBackup(event, details) {
	let mainEvent = cleanProperties(event);
	mainEvent.$id = event.$id;
	let eventDetails = cleanProperties(details);
	eventDetails.$id = details.$id;
	let request = await api.createDocument(Server.databaseID, Server.collections['TeamuitjeRequest'], mainEvent);
	eventDetails.requestId = request.$id;
	await api.createDocument(Server.databaseID, Server.collections['TeamuitjeRequestDetails'], eventDetails);
	return 2;
}

export function createTeamuitjeFormData(event) {
	if(event && event.data && event.data.mainRequest) {
		let teamUitjeFormData = { ...getData(event.data.mainRequest, teamUitjeFormFields), ...getData(event.data.detailsRequest, teamUitjeFormFields) };
		return teamUitjeFormData;
	}
	return { amountOfParticipants: 12, lunch: false, sendEmails: false };

}

export function validateTeamuitjeForm(teamUitjeFormData) {
	let errors = {};
	errors['daypart'] = !teamUitjeFormData.daypart;
	errors['firstName'] = !teamUitjeFormData.firstName;
	errors['lastName'] = !teamUitjeFormData.lastName;
	errors['emailAddress'] = !(teamUitjeFormData.emailAddress && teamUitjeFormData.emailAddress.match(/[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$/));
	errors['phoneNumber'] = !teamUitjeFormData.phoneNumber;
	errors['organizationName'] = !teamUitjeFormData.organizationName;
	errors['organizationAddress'] = !teamUitjeFormData.organizationAddress;
	errors['organizationPostalCode'] = !teamUitjeFormData.organizationPostalCode;
	errors['organizationCity'] = !teamUitjeFormData.organizationCity;
	let errorsFound = Object.keys(errors).length > 0;
	return { errors, errorsFound };
}
