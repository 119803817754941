import {
    ChevronDownIcon,
    ChevronLeftIcon,
    ChevronRightIcon
} from '@heroicons/react/20/solid';
import { useState, useEffect } from 'react';


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


export default function SmallCalendar({ value, onChange }) {

    const amsterdamTimezone = 'Europe/Amsterdam';
    const currentDate = new Date().toLocaleString("en-US", {timeZone: amsterdamTimezone});
    const currentYear = new Date(currentDate).getFullYear();
    const currentMonth = new Date(currentDate).getMonth();

    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [selectedMonth, setSelectedMonth] = useState(currentMonth + 1); // JavaScript months are 0-based

    const selectPreviousMonth = () => {
        if (selectedMonth === 1) {
            setSelectedYear(prevYear => prevYear - 1);
            setSelectedMonth(12);
        } else {
            setSelectedMonth(prevMonth => prevMonth - 1);
        }
    };

    const selectNextMonth = () => {
        if (selectedMonth === 12) {
            setSelectedYear(prevYear => prevYear + 1);
            setSelectedMonth(1);
        } else {
            setSelectedMonth(prevMonth => prevMonth + 1);
        }
    };

    const onDayClick = (date) => {
        console.log("date clicked", date);
        onChange(date);
    }

    useEffect(() => {
        if(value) {
            console.log("value change in date", value);
            const [day, month, year] = value.split('-');
            setSelectedYear(Number(year));
            setSelectedMonth(Number(month));
        }
    }, [value])

    const days = getWeekdays(selectedYear || currentYear, selectedMonth).map(x => ({ ...x, events: [] }));
    const selectedDay = days.find((day) => day.isSelected)

    const monthName = (new Date(selectedYear, selectedMonth - 1, 1, 12)).toLocaleString('nl-NL', { timeZone: 'Europe/Amsterdam', month: 'long' });

    return <div>

        <div className=" lg:flex lg:flex-auto lg:flex-col overflow-hidden rounded-xl border">
            <div>
                <div className=" text-black text-xs font-semibold  lg:flex p-2 justify-center">
                    <div className="flex flex-row justify-center items-center gap-4 px-2 w-full">
                        <div>
                            <button onClick={selectPreviousMonth}><ChevronLeftIcon className="w-6" /></button>
                        </div>
                        <div className="flex-1 text-center">
                            <span className="">{monthName} {selectedYear}</span>
                        </div>
                        <div>
                            <button onClick={selectNextMonth}><ChevronRightIcon className="w-6" /></button>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-7 text-center text-xs font-semibold text-black lg:flex-none">
                    <div className=" p-1">ma</div>
                    <div className=" p-1">di</div>
                    <div className=" p-1">wo</div>
                    <div className=" p-1">do</div>
                    <div className=" p-1">vr</div>
                    <div className=" p-1">za</div>
                    <div className=" p-1">zo</div>
                </div>
            </div>
            <div className="flex text-xs leading-6 text-black lg:flex-auto">
                <div className=" w-full grid grid-cols-7 grid-rows-6 gap-1 text-center">
                    {days.map((day) => {
                        const dayCard = undefined;
                        return (<div onClick={() => !day.isPast && day.isCurrentMonth && onDayClick(day.fullDate)}
                            key={day.fullDate}
                            className={classNames(' relative z-10 px-2 py-1 text-right flex flex-col items-center cursor-pointer  rounded-full text-center',
                                day.fullDate == value ? 'bg-cal-blue text-white' : 'bg-white',
                                !(day.fullDate == value) && day.isCurrentMonth ? ' hover:bg-gray-100' : '',
                                day.isPast ? 'text-gray-400' : 'text-black'
                            )}
                        >   
                            <time
                                dateTime={day.fullDate}
                                className={classNames(" relative z-30")} >
                                {day.isCurrentMonth && day.date}
                            </time>
                            { dayCard?.component  }
                           
                        </div>)
                    })}
                </div>
               
            </div>
        </div>
    </div>

}

function getWeekdays(year, month) {
    const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday','Saturday','Sunday'];
    const amsterdamTimezone = 'Europe/Amsterdam';
    const currentDate = new Date().toLocaleString("nl-NL", {timeZone: amsterdamTimezone});
    const currentYear = new Date(currentDate).getFullYear();
    const currentMonth = new Date(currentDate).getMonth();
    const currentDay = new Date(currentDate).getDate();
    
    let startDate = new Date(year, month - 1, 1, 12);
    startDate.setDate(startDate.getDate() - (startDate.getDay() === 0 ? 6 : startDate.getDay() - 1)); // adjust to previous Monday
    let endDate = new Date(year, month, 0);
    endDate.setDate(endDate.getDate() + (endDate.getDay() === 0 ? 0 : 7 - endDate.getDay())); // adjust to next Sunday
  
    let result = [];
  
    for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
        // console.log(d.getUTCDay());
      if (weekdays.includes(weekdays[d.getUTCDay()])) {
        result.push({
            month: d.toLocaleString("nl-NL", {timeZone: amsterdamTimezone, month: 'long'}),
          dayOfWeek: weekdays[d.getUTCDay() - 1],
          date: d.getUTCDate(),
          isPast: d.getTime() < new Date().getTime(),
          fullDate: `${String(d.getUTCDate()).padStart(2, '0')}-${String(d.getUTCMonth() + 1).padStart(2, '0')}-${d.getUTCFullYear()}`,
          isCurrentMonth: d.getMonth() === (month - 1),
          isToday: d.getFullYear() === currentYear && d.getMonth() === currentMonth && d.getDate() === currentDay
        });
      }
    }
    return result;
  }

