export function getData(data, fields, exclude = [], include = 'all') {
	let result = {};
	if(include == 'all') {
		include = fields;
	}
	for(let field of include) {
		if(!exclude.includes(field) && data.hasOwnProperty(field)) {
			result[field] = data[field];
		}
	}
	return result;
} 
