
import { useCalendarEventContext } from "Context/CalendarEventContext";
import { usePublicSettingsContext } from "Context/PublicSettingsContext";
import {Server} from "Utils/config";
import JSZip from "jszip";

export default function CreateBackupButton() {

    const { unavailableDates, calendarEvents, calendarEventsDetails, participants } = useCalendarEventContext();
    const { publicSettings } = usePublicSettingsContext();


    const onCreateBackup = async () => {
        console.log("create backup");
        console.log("unavailableDates", unavailableDates);
        console.log("calendarEvents", calendarEvents);
        console.log("calendarEventsDetails", calendarEventsDetails);
        console.log("participants", participants);


        const zip = new JSZip();

        Object.values(unavailableDates).forEach((date) => {
            zip.file(`${Server.databaseID}/${Server.collections['UnavailableDates']}/${date.$id}.json`, JSON.stringify(date));
        });

        Object.values((calendarEvents)).forEach((events) => {
            Object.values(events).forEach((event) => {
                zip.file(`${Server.databaseID}/${event.$collectionId}/${event.$id}.json`, JSON.stringify(event));
            });
        });

        Object.values(calendarEventsDetails).forEach((details) => {
            Object.values(details).forEach((detail) => {
                zip.file(`${Server.databaseID}/${detail.$collectionId}/${detail.$id}.json`, JSON.stringify(detail));
            });
        });

        Object.values(participants).forEach((participantGroup) => {
            Object.values(participantGroup).forEach((participant) => {
                zip.file(`${Server.databaseID}/${participant.$collectionId}/${participant.$id}.json`, JSON.stringify(participant));
            });
        });

        Object.values(publicSettings).forEach((setting) => {
            zip.file(`${Server.databaseID}/${Server.collections['PublicSettings']}/${setting.$id}.json`, JSON.stringify(setting));
        });

        let content = await zip.generateAsync({ type: "blob" })

        let currentDateString = new Date().toISOString().split('T')[0];
        var url = window.URL.createObjectURL(content);
        var a = document.createElement("a");
        a.href = url;

        a.download = `backup-jarige-job-admin-${currentDateString}.zip`; // Set the file name for the download
        document.body.appendChild(a); // Append the anchor to the body
        a.click(); // Simulate click on the anchor
        document.body.removeChild(a); // Remove the anchor from the body
        window.URL.revokeObjectURL(url); // Clean up the URL object

    }


    return <button className="bg-cal-blue text-white px-4 py-2 rounded-md " onClick={onCreateBackup}>

        Backup maken & downloaden

    </button>

}