import React, { createContext, useContext, useState, useEffect } from 'react';
import { 
    useGetAanpakkerVoorEenDagRequestList, 
    useGetTeamuitjeRequestList, 
    useGetHeldenavondRequestList,
    useGetAanpakkerVoorEenDagRequestDetailsList,
    useGetHeldenavondRequestDetailsList,
    useGetTeamuitjeRequestDetailsList,
    useGetAanpakkerVoorEenDagRequestParticipantList,
    useGetUnavailableDateList

} from "../Hooks";
import { LoadingOverlay } from '../Components/LoadingOverlay';

function formatDate(dateString) {
	const date = new Date(dateString);
	const day = date.getDate().toString().padStart(2, '0');
	const month = (date.getMonth() + 1).toString().padStart(2, '0');
	const year = date.getFullYear().toString();
	return `${day}-${month}-${year}`;
  }


  
const CalendarEventContext = createContext();

// Create a provider component
export default function CalendarEventProvider({ children }) {

    const [stale, setStale] = useState({ stale: false });

    const [{ AanpakkerVoorEenDagRequestList, isLoading: AisLoading, isError: AisError }] = useGetAanpakkerVoorEenDagRequestList(stale);
    const [{ HeldenavondRequestList, isLoading: BisLoading, isError: BisError }] = useGetHeldenavondRequestList(stale);
    const [{ TeamuitjeRequestList, isLoading: CisLoading, isError: CisError }] = useGetTeamuitjeRequestList(stale);
    const [{ AanpakkerVoorEenDagRequestDetailsList, isLoading: DisLoading, isError: DisError }] = useGetAanpakkerVoorEenDagRequestDetailsList(stale);
    const [{ HeldenavondRequestDetailsList, isLoading: EisLoading, isError: EisError }] = useGetHeldenavondRequestDetailsList(stale);
    const [{ TeamuitjeRequestDetailsList, isLoading: FisLoading, isError: FisError }] = useGetTeamuitjeRequestDetailsList(stale);
    const [{ AanpakkerVoorEenDagRequestParticipantList, isLoading: GisLoading, isError: GisError }] = useGetAanpakkerVoorEenDagRequestParticipantList(stale);
    const [{ UnavailableDateList, isLoading: HisLoading, isError: HisError }] = useGetUnavailableDateList(stale);

    const loading = AisLoading || BisLoading || CisLoading || DisLoading || EisLoading || FisLoading || GisLoading || HisLoading;
    // console.log("HeldenavondRequestList", HeldenavondRequestList);
    // console.log("AanpakkerVoorEenDagParticipantsList", AanpakkerVoorEenDagRequestParticipantList)

    const [calendarEvents, setCalendarEvents] = useState({});
    const [calendarEventsDetails, setCalendarEventsDetails] = useState({});
    const [participants, setParticipants] = useState({});
    const [unavailableDates, setUnavailableDates] = useState({});

    const reload = () => {
        setStale(x => !x);
    }

    useEffect(() => {
        let _calendarEvents = [];
        let _calendarEventsDetails = {};
        let _participants = {};
        
        
        AanpakkerVoorEenDagRequestList.forEach((item) => {
            _calendarEvents.push({ ...item, $$type: "aanpakker" });
        })

        HeldenavondRequestList.forEach((item) => {
            _calendarEvents.push({ ...item, $$type: "heldenavond" });
        })

        TeamuitjeRequestList.forEach((item) => {
            _calendarEvents.push({ ...item, $$type: "teamuitje" });
        })

        let groupedStructure = _calendarEvents.reduce((col, item) => {
            let events = col[formatDate(item.date)]
            if(!events) {
                events = {};
            }
            return ({...col, [formatDate(item.date)]: {...events, [item.$id]:item } })
        }, {});

        AanpakkerVoorEenDagRequestDetailsList.forEach((item) => {

            if(!_calendarEventsDetails[item.requestId]) {
                _calendarEventsDetails[item.requestId] = {};
            }

            _calendarEventsDetails[item.requestId] = {..._calendarEventsDetails[item.requestId], [item.$id]: item};
        })

        HeldenavondRequestDetailsList.forEach((item) => {

            if(!_calendarEventsDetails[item.requestId]) {
                _calendarEventsDetails[item.requestId] = {};
            }

            _calendarEventsDetails[item.requestId] = {..._calendarEventsDetails[item.requestId], [item.$id]: item};
        })

        TeamuitjeRequestDetailsList.forEach((item) => {

            if(!_calendarEventsDetails[item.requestId]) {
                _calendarEventsDetails[item.requestId] = {};
            }

            _calendarEventsDetails[item.requestId] = {..._calendarEventsDetails[item.requestId], [item.$id]: item};
        })

        AanpakkerVoorEenDagRequestParticipantList.forEach((item) => {

            if(!_participants[item.requestId]) {
                _participants[item.requestId] = {};
            }

            _participants[item.requestId] = {..._participants[item.requestId], [item.$id]: item};
        });

        
        setCalendarEventsDetails(_calendarEventsDetails);
        setCalendarEvents(groupedStructure);
        setParticipants(_participants);

    }, [
        AanpakkerVoorEenDagRequestList, 
        HeldenavondRequestList, 
        TeamuitjeRequestList,
        AanpakkerVoorEenDagRequestDetailsList,
        HeldenavondRequestDetailsList,
        TeamuitjeRequestDetailsList,
        AanpakkerVoorEenDagRequestParticipantList
    ]);


    useEffect(() => {
        let _unavailableDates = [];
        UnavailableDateList.forEach((item) => {
            _unavailableDates.push({ ...item, date: formatDate(item.date) });
        });

        setUnavailableDates(_unavailableDates);
    }, [UnavailableDateList])

    // Define any additional state or functions related to calendar events

    return (
        <CalendarEventContext.Provider value={{ unavailableDates, calendarEvents,calendarEventsDetails,participants, reload }}>
           <LoadingOverlay loading={loading} />
            {children}
        </CalendarEventContext.Provider>
    );
};

export const useCalendarEventContext = () => {
    return useContext(CalendarEventContext);
};

