import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { FetchState } from '../Hooks';
import api  from '../api/api';

function DropdownProfile({
    align, dispatch, user
}) {

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const trigger = useRef(null);
    const dropdown = useRef(null);

    // close on click outside
    useEffect(() => {
        const clickHandler = ({ target }) => {
            if (!dropdown.current) return;
            if (!dropdownOpen || dropdown.current.contains(target) || trigger.current.contains(target)) return;
            setDropdownOpen(false);
        };
        document.addEventListener('click', clickHandler);
        return () => document.removeEventListener('click', clickHandler);
    });

    // close if the esc key is pressed
    useEffect(() => {
        const keyHandler = ({ keyCode }) => {
            if (!dropdownOpen || keyCode !== 27) return;
            setDropdownOpen(false);
        };
        document.addEventListener('keydown', keyHandler);
        return () => document.removeEventListener('keydown', keyHandler);
    });

    const handleLogout = async () => {
        dispatch({ type: FetchState.FETCH_INIT });
        try {
            await api.deleteCurrentSession();
        dispatch({ type: FetchState.FETCH_SUCCESS, payload: null });
        } catch (e) {
            dispatch({ type: FetchState.FETCH_FAILURE });
        } finally {
            setDropdownOpen(false)
        }
    }

    return (
        <div className="relative inline-flex">
            <button
                ref={trigger}
                className="inline-flex justify-center items-center group px-4 gap-4 border-l"
                aria-haspopup="true"
                onClick={() => setDropdownOpen(!dropdownOpen)}
                aria-expanded={dropdownOpen}
            >
                <img className="w-8 h-8 rounded-full" src={`${process.env.PUBLIC_URL}/assets/user-avatar-32.png`} width="32" height="32" alt="User" />
                <div className="flex items-center truncate gap-8">
                    <span className="truncate ml-2 text-sm font-medium group-hover:text-slate-800">{user.name}</span>
                    <ChevronDownIcon className="w-5 h-5 ml-1 text-slate-400 group-hover:text-slate-500" />
                </div>
            </button>

            <Transition
                className={`origin-top-right z-10 absolute top-full min-w-44 bg-white border border-slate-200 py-1.5 rounded shadow-lg overflow-hidden mt-1 ${align === 'right' ? 'right-0' : 'left-0'}`}
                show={dropdownOpen}
                enter="transition ease-out duration-200 transform"
                enterStart="opacity-0 -translate-y-2"
                enterEnd="opacity-100 translate-y-0"
                leave="transition ease-out duration-200"
                leaveStart="opacity-100"
                leaveEnd="opacity-0"
            >
                <div
                    ref={dropdown}
                    onFocus={() => setDropdownOpen(true)}
                    onBlur={() => setDropdownOpen(false)}
                >
                    <div className="pt-0.5 pb-2 px-3 mb-1 border-b border-slate-200">
                        <div className="text-xs text-slate-500 italic">Administrator</div>
                    </div>
                    <ul>
                        <li>
                            <Link
                                className="font-medium text-sm text-indigo-500 hover:text-indigo-600 flex items-center py-1 px-3"
                                to="/signin"
                                onClick={() => handleLogout()}
                            >
                                Uitloggen
                            </Link>
                        </li>
                    </ul>
                </div>
            </Transition>
        </div>
    )
}

export default DropdownProfile;