import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import CompleteApp from './App';
import reportWebVitals from './reportWebVitals';
import {
	createBrowserRouter,
	RouterProvider,
} from "react-router-dom";
import AdminCalendar from "./Pages/AdminCalendar";
import AdminRequestList from './Pages/AdminRequestList';
import AdminEmails from 'Pages/AdminEmails';

const router = createBrowserRouter([
	{
		path: "/",
		element: <CompleteApp />,
		children: [
			{
				index: true,
				element: <AdminCalendar />,
			},
			{
				path: "aanvragen",
				element: <AdminRequestList />,
			},
			{
				path: "emails",
				element: <AdminEmails />,
			}
		]
	},
	
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
