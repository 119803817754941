import api from "api/api";
import { Server } from "Utils/config";
import { getData } from "Utils/form";
import { cleanProperties } from "Utils/object";

const heldenAvondFormFields = [
	'date',
	'firstName', 
	'lastName', 
	'emailAddress', 
	'phoneNumber', 
	'comments',
	'birthdate',
    "status",
	'sendEmails'
]

export async function updateHeldenAvondAction(heldenAvondFormData, event) {
	let mainRequestToSave = { ...getData(heldenAvondFormData, heldenAvondFormFields, [], ['date', 'status']) };
	mainRequestToSave.status = mainRequestToSave.status ?? 'pending';
	delete mainRequestToSave.$$type;
	await api.updateDocument(Server.databaseID, Server.collections['HeldenavondRequest'], event.data.mainRequest.$id, mainRequestToSave );
	let detailsRequestToSave = { ...getData(heldenAvondFormData, heldenAvondFormFields, ['date', 'status']) };
	if(event.data.detailsRequest.$id) {
		console.log("detailsRequestToSave", detailsRequestToSave);
		await api.updateDocument(Server.databaseID, Server.collections['HeldenavondRequestDetails'], event.data.detailsRequest.$id, detailsRequestToSave);
	} else {
		console.log("detailsRequestToSave (creating)", detailsRequestToSave);
		detailsRequestToSave.requestId = mainRequestToSave.$id;
		await api.createDocument(Server.databaseID, Server.collections['HeldenavondRequestDetails'], detailsRequestToSave);
	}
	return true;
}

export async function addHeldenAvondAction(heldenAvondFormData, selectedDay) {
	let mainRequestToSave = { ...getData(heldenAvondFormData, heldenAvondFormFields, [], ['date', 'status']) };
	mainRequestToSave.date = selectedDay;
	mainRequestToSave.status = mainRequestToSave.status ?? 'pending';
	let request = await api.createDocument(Server.databaseID, Server.collections['HeldenavondRequest'], mainRequestToSave);
	let requestId = request.$id;
	let detailsRequestToSave = { ...getData(heldenAvondFormData, heldenAvondFormFields, ['date', 'status']) };
	let createData = { ...detailsRequestToSave, requestId: requestId };
	await api.createDocument(Server.databaseID, Server.collections['HeldenavondRequestDetails'], createData);
	return true;
}

export async function restoreHeldenAvondBackup(event, details) {
	let mainEvent = cleanProperties(event);
	mainEvent.$id = event.$id;
	let eventDetails = cleanProperties(details);
	eventDetails.$id = details.$id;
	let request = await api.createDocument(Server.databaseID, Server.collections['HeldenavondRequest'], mainEvent);
	eventDetails.requestId = request.$id;
	await api.createDocument(Server.databaseID, Server.collections['HeldenavondRequestDetails'], eventDetails);
	return 2;
}


export function validateHeldenAvondForm(heldenAvondFormData) {
    let errors = {};

    errors['firstName'] = !heldenAvondFormData.firstName;
    errors['lastName'] = !heldenAvondFormData.lastName;
    errors['emailAddress'] = !(heldenAvondFormData.emailAddress && heldenAvondFormData.emailAddress.match(/[A-Za-z0-9._%+\-]+@[A-Za-z0-9.\-]+\.[A-Za-z]{2,}$/));
    errors['phoneNumber'] = !heldenAvondFormData.phoneNumber;
    errors['birthdate'] = !heldenAvondFormData.birthdate;
    let foundErrors = Object.keys(errors).length > 0
    return { errors, foundErrors }
}

export function createHeldenAvondFormData(event) {
    if(event && event.data && event.data.mainRequest) {
        let heldenAvondFormData = { 
            ...getData(event.data.mainRequest, heldenAvondFormFields), 
            ...getData(event.data.detailsRequest, heldenAvondFormFields),
            birthdate: event.data.detailsRequest?.birthdate ? new Date(event.data.detailsRequest?.birthdate) : null
        };
        return heldenAvondFormData;
    } 
    return { sendEmails: false };
}