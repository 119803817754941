import { Client as Appwrite, Databases, Account } from "appwrite";
import { Server } from "../Utils/config";
import appwriteApi from './api.js';
import axios from "axios";

export function createTeamUitjeRequest(teamuitje) {
    return axios.post(`${Server.backendURL}/api/1.0/teamuitje-requests`, teamuitje, {
        headers: {
            'content-type': 'application/json'
        }
    });
}

export function createAanpakkerVoorEenDagRequest(aanpakkerVoorEenDag) {
    return axios.post(`${Server.backendURL}/api/1.0/aanpakker-voor-een-dag-requests`, aanpakkerVoorEenDag);
}

export function createHeldenavondRequest(heldenavond) {
    return axios.post(`${Server.backendURL}/api/1.0/heldenavond-requests`, heldenavond);
}

async function getAppwriteAccessToken() {

    let appwriteAccessToken;
    appwriteAccessToken = appwriteApi.provider();
    appwriteAccessToken = appwriteAccessToken.account;
    appwriteAccessToken = await appwriteAccessToken.createJWT();
    appwriteAccessToken = appwriteAccessToken.jwt;

    return appwriteAccessToken;


}

// hoofdcollectie => 
// metadata.requestId String
// metadata.requestType Enum('aanpakker-voor-dag', 'heldenavond', 'teamuitje')

const mailhimpEmailTemplates = [
    'aanpakker-voor-dag-request-confirmation',
    'aanpakker-voor-een-dag-thanks',
    'heldenavond-reminder',
    'heldenavond-request-confirmation',
    'teamuitje-reminder',
    'teamuitje-request-confirmation',
    'teamuitje-thanks',
]

export async function getEmails({ 
    limit = 1000, 
    inclusiveLowerBound = '2023-01-01T00:00:00.000Z',
    inclusiveUpperBound = ''
} = {
}) {
    let appwriteAccessToken = await getAppwriteAccessToken();

    let query = [];
    if(limit) {
        query.push(`limit=${limit}`);
    }
    if(inclusiveLowerBound) {
        query.push(`inclusiveLowerBound=${inclusiveLowerBound}`);
    }
    if(inclusiveUpperBound) {
        query.push(`inclusiveUpperBound=${inclusiveUpperBound}`);
    }

    let queryString = query.join('&');
    queryString = queryString ? `?${queryString}` : '';

    const url = new URL(`/api/2.4/emails${queryString}`, Server.backendURL);
    let requestOptions = {
        method: 'GET',
        // mode: 'no-cors', // 'cors' by default
        headers: {
            Authorization: `Bearer ${appwriteAccessToken}`
        }
    };

    const response = await fetch(url, requestOptions);

    if(!response.ok) {
        const contentType = response.headers.get('Content-Type');
        if (contentType.startsWith('application/json')) {
            console.error(await response.json());
        } else {
            console.error(await response.text());
        }
        throw new Error;
    }

    let headers = response.headers;
    headers.forEach((value, name) => {
        console.log(`${name}: ${value}`);
    });
    const emails = await response.json();
    return emails;

}

/**
 * String -> Promise (Array String)
 * @param requestId String
 * @return Promise (Array String)
 */
export async function sendAanpakkerVoorEenDagRequestConfirmationEmail(requestId) {

    let appwriteAccessToken = await getAppwriteAccessToken();

    const url = new URL(`/api/2.4/aanpakker-voor-een-dag-requests/${requestId}/emails/confirmation`, Server.backendURL);

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${appwriteAccessToken}`
        }
    });

    if(!response.ok) {

        const contentType = response.headers.get('Content-Type');

        if (contentType.startsWith('application/json')) {
            console.error(await response.json());
        } else {
            console.error(await response.text());
        }

        throw new Error;

    }

    const recipients = await response.json();

    return recipients;

}

/**
 * String -> Promise String
 * @param requestId String
 * @return Promise String
 */
export async function sendHeldenavondRequestConfirmationEmail(requestId) {

    let appwriteAccessToken = await getAppwriteAccessToken();

    const url = new URL(`/api/2.4/heldenavond-requests/${requestId}/emails/confirmation`, Server.backendURL);

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${appwriteAccessToken}`
        }
    });

    if(!response.ok) {

        const contentType = response.headers.get('Content-Type');

        if (contentType.startsWith('application/json')) {
            console.error(await response.json());
        } else {
            console.error(await response.text());
        }

        throw new Error;

    }

    const recipient = await response.json();

    return recipient;

}

/**
 * String -> Promise String
 * @param requestId String
 * @return Promise String
 */
export async function sendTeamuitjeRequestConfirmationEmail(requestId) {

    let appwriteAccessToken = await getAppwriteAccessToken();

    const url = new URL(`/api/2.4/teamuitje-requests/${requestId}/emails/confirmation`, Server.backendURL);

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${appwriteAccessToken}`
        }
    });

    if(!response.ok) {

        const contentType = response.headers.get('Content-Type');

        if (contentType.startsWith('application/json')) {
            console.error(await response.json());
        } else {
            console.error(await response.text());
        }

        throw new Error;

    }

    const recipient = await response.json();

    return recipient;

}


export async function sendConfirmationEmail(type, requestId) {
    switch (type) {
        case 'aanpakker': {
            const recipients = await sendAanpakkerVoorEenDagRequestConfirmationEmail(requestId);
            for (const recipient of recipients) {
                console.log(`email sent to \`${recipient}\``)
            }
            break;
        }
        case 'heldenavond': {
            const recipient = sendHeldenavondRequestConfirmationEmail(requestId);
                console.log(`email sent to \`${recipient}\``)
            break;
        }
        case 'teamuitje': {
            const recipient = await sendTeamuitjeRequestConfirmationEmail(requestId);
            console.log(`email sent to \`${recipient}\``)
            break;
        }
        default:
            console.warn(`unexpected request type \`${type}\``)
    }
    return true;
}

