import { useBackupContext } from "Context/BackupContext";
import { restoreAanpakkerBackup  } from "Components/Forms/Actions/Aanpakker";
import { restoreHeldenAvondBackup } from "Components/Forms/Actions/Heldenavond";
import { restoreTeamuitjeBackup } from "Components/Forms/Actions/Teamuitje";
import { useCalendarEventContext } from "Context/CalendarEventContext";
import { usePublicSettingsContext } from "Context/PublicSettingsContext";
import { useState } from "react";
import { Server } from "Utils/config";
import { cleanProperties } from "Utils/object";
import api from "api/api";

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}


export default function BackupMenuContainer() {

    const { calendarEvents, unavailableDates:currentUnavailableDates } = useCalendarEventContext();
    const { publicSettings:currentPublicSettings } = usePublicSettingsContext();
    const { backup, backupMode, setBackupMode, restoreQueue, setRestoreQueue, backupOptions } = useBackupContext();

    // console.log("backup menu container", currentPublicSettings)
    // console.log("current unavailable dates", currentUnavailableDates)


    const [restoring, setRestoring] = useState(undefined);
   
    const onRestore = async () => {
        console.clear();
        console.log("backup", backup);
        console.log("restore", restoreQueue);
        console.log("backupOptions", backupOptions);

        // return;

        let keys = Object.keys(restoreQueue);
        setRestoring((restoring) => ({ total: keys.length, current: 0, restored: {}, type: 'events' }));
        for(let keyIndex in keys) {
            let key = keys[keyIndex];
            const item = restoreQueue[key];

            let date = item.date;
            let dateKey = date.split('T')[0];
            let dateKeyParts = dateKey.split('-');
            dateKey = `${dateKeyParts[2]}-${dateKeyParts[1]}-${dateKeyParts[0]}`;

            console.log("dateKey", dateKey);
            if(calendarEvents[dateKey] && calendarEvents[dateKey][item.$id]) {
                console.log("already exists", item);
                setRestoring((restoring) => ({ 
                    ...restoring,
                    current: restoring.current + 1,
                    restored: { ...restoring.restored, [key]: { status: "exists" } }
                }));
                continue;
            } ;

            // console.log("key", key);
            if(!item) {
                console.log("no item", key);
                setRestoring((restoring) => ({ 
                    ...restoring,
                    current: restoring.current + 1,
                    restored: { ...restoring.restored, [key]: { status: "no item" } }
                }));
                continue;
            }
            let details = backup.calendarEventsDetails[item.$id];
            if(details) {
                details = Object.values(details)[0];
            }
            if(!details) {
                setRestoring((restoring) => ({ 
                    ...restoring,
                    current: restoring.current + 1,
                    restored: { ...restoring.restored, [key]: { status: "no details" } }
                }));
                continue;
            }

            if(item.$$type == "aanpakker") {
                let participants = backup.participants[item.$id];
                if(participants) {
                    participants = Object.values(participants);
                } else {
                    participants = [];
                }

                let sleepFor = (2 + participants.length) * 500;

                try {
                    await restoreAanpakkerBackup(item, details, participants);
                    setRestoring((restoring) => ({ 
                        ...restoring,
                        current: restoring.current + 1,
                        restored: { ...restoring.restored, [key]: { status: "restored" } }
                    }));
                } catch(err) {
                    setRestoring((restoring) => ({ 
                        ...restoring,
                        current: restoring.current + 1,
                        restored: { ...restoring.restored, [key]: { status: "error" } }
                    }));
                    console.log("error", err);
                } 

                await sleep(sleepFor);
            }

            if(item.$$type == "heldenavond") {
                console.log("restore heldenavond");
                console.log("details", details);
                let sleepFor = 2 * 500;
                try {
                    await restoreHeldenAvondBackup(item, details);
                    setRestoring((restoring) => ({ 
                        ...restoring,
                        current: restoring.current + 1,
                        restored: { ...restoring.restored, [key]: { status: "restored" } }
                    }));
                } catch(err) {
                    console.log("error", err);
                    setRestoring((restoring) => ({ 
                        ...restoring,
                        current: restoring.current + 1,
                        restored: { ...restoring.restored, [key]: { status: "error" } }
                    }));
                }
                await sleep(sleepFor);
            }

            if(item.$$type == "teamuitje") {
                let sleepFor = 2 * 500;
                console.log("restore teamuitje");
                console.log("details", details);
                try {
                    await restoreTeamuitjeBackup(item, details);
                    setRestoring((restoring) => ({ 
                        ...restoring,
                        current: restoring.current + 1,
                        restored: { ...restoring.restored, [key]: { status: "restored" } }
                    }));
                } catch(err) {
                    console.log("error", err);
                    setRestoring((restoring) => ({ 
                        ...restoring,
                        current: restoring.current + 1,
                        restored: { ...restoring.restored, [key]: { status: "error" } }
                    }));
                } 
                await sleep(sleepFor);
            }
           
        };

        
        if(backupOptions.includeUnavailableDates) {
            setRestoring((restoring) => ({ total: unavailableDatesInBackup.length, current: 0, restored: {}, type: 'unavailableDates' }));
           let unavailableDatesInBackup = backup.unavailableDates;
           let unavailableDatesInSystem = currentUnavailableDates.reduce((col, item) => ({ ...col, [item.$id]: item }), {});
           
           for(let date of unavailableDatesInBackup) {
            // console.log("date", date);
            if(unavailableDatesInSystem[date.$id]) {
                setRestoring((restoring) => ({ 
                    ...restoring,
                    current: restoring.current + 1,
                    restored: { ...restoring.restored, [date.$id]: { status: "exists" } }
                }));
                continue;
            }
            
            let dateToSave = cleanProperties(date);
            dateToSave.$id = date.$id;
            try {
                await api.createDocument(Server.databaseID, Server.collections['UnavailableDate'], dateToSave);
                setRestoring((restoring) => ({ 
                    ...restoring,
                    current: restoring.current + 1,
                    restored: { ...restoring.restored, [date.$id]: { status: "restored" } }
                }));
            } catch(err) {
                console.log("error", err);
                setRestoring((restoring) => ({ 
                    ...restoring,
                    current: restoring.current + 1,
                    restored: { ...restoring.restored, [date.$id]: { status: "error" } }
                }));
            } 
           
            await sleep(500);

           }
        }

        
        if(backupOptions.includeSettings) {
            setRestoring((restoring) => ({ total: Object.values(settingsInBackup).length, current: 0, restored: {}, type: 'settings' }));
            let settingsInBackup = backup.publicSettings;
            for(let setting of Object.values(settingsInBackup)) {
                // console.log("setting", setting);
                if(currentPublicSettings[setting.$id]) {
                    setRestoring((restoring) => ({ 
                        ...restoring,
                        current: restoring.current + 1,
                        restored: { ...restoring.restored, [setting.$id]: { status: "exists" } }
                    }));
                    continue;
                }
                let settingToSave = cleanProperties(setting);
                settingToSave.$id = setting.$id;
                
                try {
                    await api.createDocument(Server.databaseID, Server.collections['PublicSettings'], settingToSave);
                    setRestoring((restoring) => ({ 
                        ...restoring,
                        current: restoring.current + 1,
                        restored: { ...restoring.restored, [setting.$id]: { status: "restored" } }
                    }));
                } catch(err) {
                    console.log("error", err);
                    setRestoring((restoring) => ({ 
                        ...restoring,
                        current: restoring.current + 1,
                        restored: { ...restoring.restored, [setting.$id]: { status: "error" } }
                    }));
                } 
              
                
            }
        }

        setRestoreQueue({});
        setRestoring(undefined);
        setBackupMode(false);

    }


    

    const onCancelBackup = () => {
        setBackupMode(false);
    }

    if(!backupMode) return null;

    return <BackupMenuPanel restoring={restoring} onCancelBackup={onCancelBackup} restoreQueue={restoreQueue} onRestore={onRestore} />
}

const restoreTypeLabels = {
    events: "Evenementen",
    unavailableDates: "Niet beschikbare dagen",
    settings: "Instellingen"
}

export function BackupMenuPanel({ onCancelBackup, restoreQueue, onRestore, restoring }) {
    return <div className="top-0 left-1/2 transform -translate-x-1/2 fixed px-8 z-20 py-4 bg-green-500 shadow-lg rounded-b-2xl">
        <div className="flex flex-row gap-4 p-2 items-center">
            <div className="text-2xl text-center">Terugzetten backup</div>
            <div>
                { Object.keys(restoreQueue).length > 0 && <button 
                    disabled={restoring}
                    onClick={onRestore} 
                    className="bg-white/50 text-black px-4 py-2 rounded-md">
                   { !restoring && <span>Herstel {Object.keys(restoreQueue).length} items</span> }
                   { restoring && <span>Herstellen {restoreTypeLabels[restoring.type]} {(restoring.current)} van {restoring.total}</span> }
                </button> }
            </div>
            <div>
                <button onClick={onCancelBackup} className="bg-white/50 text-black px-4 py-2 rounded-md">Annuleren</button>
            </div>
        </div>
    </div>
}