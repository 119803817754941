import { DateTime } from 'luxon';
import preval       from 'babel-plugin-preval/macro';

import Calendar from "./Components/Calendar";
import { useState, useEffect, Fragment } from "react";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import UserMenu from "./Components/DropdownProfile";
import DayCard from "./Components/DayCard";
import Dialog from "./Components/Dialog";
import Login from "./Pages/Login";
import AdminCalendar from "./Pages/AdminCalendar";
import { useGetUser } from "./Hooks";
import CalendarEventProvider from "./Context/CalendarEventContext";
import ToastNotficationProvider from "./Context/ToastNotificationContext";
import { Outlet, NavLink } from "react-router-dom";
import PublicSettingsProvider from "Context/PublicSettingsContext";
import { Server } from "Utils/config";
import BackupProvider, { useBackupContext } from "Context/BackupContext";
import JSZip from "jszip";
import { BackupDialogButton } from "Components/BackupDialog";
import BackupMenuContainer from "Components/BackupMenu";
import CalendarProvider from "Context/CalendarContext";
import MailchimpEmailProvider from "Context/MailchimpEmailContext";

const buildInformation = preval `

	const { execSync }  = require('child_process');
	const { version }   = require('../package.json');

	/**
	 * String -> Null | String
	 * @param command String
	 * @retun Null | String
	 */
	const execOrNull = command => {
		try {
			return execSync(command, { encoding: 'utf8' }).trimEnd();
		} catch {
			return null;
		}
	};

	module.exports = {
		buildTime:      (new Date).toISOString(),
		gitBranch:      execOrNull('git symbolic-ref --short HEAD'),
		gitCommit:      execOrNull('git rev-parse HEAD'),
		nodeJsVersion:  process.version,
		version:        version
	};

`;

function App({ user, dispatch }) {

	const { backupMode }                                = useBackupContext();
	const [buildDialogVisible, setBuildDialogVisible]   = useState(false);

	const closeBuildDialog  = () => setBuildDialogVisible(false);
	const openBuildDialog   = () => setBuildDialogVisible(true);
	const backupHoverClass  = backupMode ? 'bg-green-700' : 'bg-blue-500';

	return (
		<Fragment>
			<div className="flex h-screen w-screen">
				<div className={`${backupMode ? 'bg-green-700': 'bg-blue-500'} flex flex-col w-72`}>
					<div className="text-2xl text-center text-white p-4">Jarige Job</div>
					<div className="p-4 font-bold text-white">
						<ul>
							<li>
								<NavLink to="/" className={({isActive, isPending}) => `block px-4 py-2 hover:${backupHoverClass} ${isActive ? (backupMode ? 'bg-green-900' : 'bg-blue-700') : ''}`}>Overzicht</NavLink>
							</li>
							<li>
								<NavLink to="/aanvragen"  className={({isActive, isPending}) => `block px-4 py-2 hover:${backupHoverClass} ${isActive ? (backupMode ? 'bg-green-900' : 'bg-blue-700') : ''}`}>Aanvragen</NavLink>
							</li>
							<li>
								<NavLink to="/emails"  className={({isActive, isPending}) => `block px-4 py-2 hover:${backupHoverClass} ${isActive ? (backupMode ? 'bg-green-900' : 'bg-blue-700') : ''}`}>Verzonden E-mails</NavLink>
							</li>
						</ul>
					</div>
					<div className="mt-auto p-4">
						<button { ... {
							children:   getVersionWithBuildTime(),
							className:  'text-white',
							onClick:    openBuildDialog,
							title:      'Version',
							type:       'button'
						} } />
					</div>
				</div>
				<div className="flex-1 flex flex-col relative overflow-y-scroll">
					<div className="w-full sitcky top-0 bg-white border-b border-b-gray-300 flex justify-end">
						<div className="p-4">
							<UserMenu align="right" dispatch={dispatch} user={user} />
						</div>
					</div>
					<div>
						<CalendarEventProvider>
							<PublicSettingsProvider>
								<MailchimpEmailProvider>
									<Outlet />
									<BackupMenuContainer />
									<BackupDialogButton />
								</MailchimpEmailProvider>
							</PublicSettingsProvider>
						</CalendarEventProvider>
					</div>
				</div>
			</div>
			<BuildInformationDialog { ... {
				onClose: closeBuildDialog,
				visible: buildDialogVisible
			} } />
		</Fragment>
	);
}

export default CompleteApp;

function CompleteApp({ children }) {

	const [{ user }, dispatch] = useGetUser();

	console.log("user",user);

	if(!user) {

		return <div>
			<Login dispatch={dispatch} />
		</div>
	}


	return <ToastNotficationProvider>
		<CalendarProvider>
			<BackupProvider>
				<App user={user} dispatch={dispatch} />
			</BackupProvider>
		</CalendarProvider>
	</ToastNotficationProvider>

}

/**
 * { onClose : () -> *, visible : Boolean } -> React.ReactNode
 * @param properties { onClose : () -> *, visible : Boolean }
 * @return React.ReactNode
 */
const BuildInformationDialog = properties => {

	const { onClose } = properties;
	const { visible } = properties;

	const { buildTime } = buildInformation;

	const tableClass = 'border-separate border-spacing-x-4 border-spacing-y-2';

	return <Dialog open={visible} onClose={onClose}>
		<div className="flex flex-col gap-4 min-w-96">
			<div className="text-2xl">
				Build information ({getVersionWithBuildTime()})
			</div>
			<table className={tableClass}>
				<tbody>
					<tr>
						<td>
							Version
						</td>
						<td>
							{buildInformation.version}
						</td>
					</tr>
					<tr>
						<td>
							Build time
						</td>
						<td>
							{formatDateTimeLocaleStringHuge(buildTime)}
						</td>
					</tr>
					<tr>
						<td>
							Git branch
						</td>
						<td>
							{buildInformation.gitBranch ?? 'Unknown'}
						</td>
					</tr>
					<tr>
						<td>
							Git commit
						</td>
						<td>
							{buildInformation.gitCommit ?? 'Unknown'}
						</td>
					</tr>
					<tr>
						<td>
							Node.js version
						</td>
						<td>
							{buildInformation.nodeJsVersion}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</Dialog>;

};

/**
 * @param value String
 * @return String
 */
const formatDateTimeBasicIso8601Utc = value => {

	value = DateTime.fromISO(value, {
		zone: 'utc'
	});

	value = value.set({
		millisecond: 0
	});

	return value.toISO({
		format:                 'basic',
		suppressMilliseconds:   true
	});

};

/**
 * @param value String
 * @return String
 */
const formatDateTimeLocaleStringHuge = value => {

	value = DateTime.fromISO(value);

	return value.toLocaleString(DateTime.DATETIME_HUGE);

};

/**
 * @return String
 */
const getVersionWithBuildTime = () => {

	const { buildTime } = buildInformation;
	const { version }   = buildInformation;

	// https://semver.org/#spec-item-10
	return `${version}+${formatDateTimeBasicIso8601Utc(buildTime)}`;

}

function readFile(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = (e) => {
			const content = e.target.result;
			resolve(content);
		}
		reader.readAsText(file);
	})

}
