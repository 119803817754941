import { CheckIcon, MinusIcon, PlusIcon } from "@heroicons/react/24/solid";

export default function NumberInput({ value, onChange, minAllowed, maxAllowed, hasError }) {

    let extraClassName = '';
    if(hasError) {
        extraClassName += ' border-red-500';
    }

    const changeAmount = (newValue) => {
        if(isNaN(newValue)) {
            return;
        }
        newValue = Number(newValue);
        if(newValue >= minAllowed && newValue <= maxAllowed) {
            onChange(newValue);
        }
    }

    return <div className={`flex flex-row gap-4 rounded-xl border border-gray-300 w-min px-4 py-1 items-center ${extraClassName}`}>
        <button className="text-black font-bold py-2" onClick={() => changeAmount(value - 1)}>
            <MinusIcon className="w-5" />
        </button>
        <div className="w-8 text-center text-base">{value}</div>
        <button className="text-black font-bold py-2" onClick={() => changeAmount(value + 1)}>
            <PlusIcon className="w-5" />
        </button>
    </div>
}