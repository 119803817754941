export const Server = {
    endpoint : process.env.REACT_APP_ENDPOINT || 'https://cloud.appwrite.io/v1',
    project: process.env.REACT_APP_PROJECT || '648c6fafddab8d5f54c0',
    // endpoint : process.env.REACT_APP_ENDPOINT || 'http://localhost:5444/v1',
    // project: process.env.REACT_APP_PROJECT || '65ca588fad1cfba9d2d8',
    collections : {
        'AanpakkerVoorEenDagRequest': '3755fa78aee8ecf74a9b037c69187f15d181',
        'AanpakkerVoorEenDagRequestDetails': 'b2f226cb9286e60ad9997f4785613938b1b4',
        'AanpakkerVoorEenDagRequestParticipant': '151c9337cf14ad0b0df9e1fadeab52b6aa30',
        'HeldenavondRequest': '24b3458ee06237eba0be31308214d21cef07',
        'HeldenavondRequestDetails': '5d79aae4707ff91bbec59a8d8e463d8e3400',
        'TeamuitjeRequest': 'ae820c72f5e86b1cf237861fa42a431b5ef2',
        'TeamuitjeRequestDetails': '8031008cb6808f938d4789af02f961343711',
        'UnavailableDate': '1bd29e9b192290c01ce873675d9ae9e08607',
        'PublicSettings': 'c0adbc52df587a66f18f6313dd484e122ff1',
    },
    databaseID : process.env.REACT_APP_DATABASE_ID || '648c7f1fe7cf8ca15b08',
    backendURL: process.env.REACT_APP_BACKEND_URL || "https://jarige-job-teamuitjes-planner-api-development.srv02.ehero.es",

}

export const calendarLabels = {
	'aanpakker': 'Aanpakker voor een dag',
	'heldenavond': 'Heldenavond',
	'teamuitje': 'Teamuitje',
}

export const statusClassName = {
	'confirmed': 'bg-green-500',
	'pending': 'bg-orange-500',
	'rejected': 'bg-red-500',
}
