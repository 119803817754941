import { Fragment, useEffect, useState } from "react";
import Dialog from "../Dialog";

import { useToastNotficationContext } from "../../Context/ToastNotificationContext";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import CalendarAvailabilityContainer from "./CalendarAvailabilityContainer";
import { usePublicSettingsContext } from "Context/PublicSettingsContext";

export function CalendarAvailabilityButton() {

    const [opened, setOpened] = useState(false);

    return <Fragment>
        <CalendarAvailabilityDialog opened={opened} onClose={() => setOpened(false)} />
        <button className="flex flex-row gap-2 text-cal-blue" onClick={() => setOpened(true)}>
            Instellen <ChevronRightIcon className="w-6" />
        </button>

      
    </Fragment>

}

export default function CalendarAvailabilityDialog({ opened, onClose }) {
    const { setToastData } = useToastNotficationContext();
    const { reload } = usePublicSettingsContext();


    const _reload = (data) => {
        if(data?.message) {
            setToastData(data);
        }
        reload();
    }

    const _onClose = (data) => {
        if(data?.message) {
            setToastData(data);
        }
    }


    


    return <Dialog open={!!opened} onClose={onClose}>
        <div className="min-w-96">
            <div className=" text-sm text-cal-blue py-2 -top-3 relative">Beschikbare kalendar instellen</div>
            <div>
                <CalendarAvailabilityContainer reload={_reload} />
            </div>
        </div>
    </Dialog>


}