import { Fragment, useEffect, useState } from "react";
import Dialog from "../Dialog";
import BackupDialogContainer from "./BackupDialogContainer";



export default function BackupDialog({ opened, onClose }) {

    

    return <Dialog open={!!opened} onClose={onClose}>
        <div className="min-w-96">
            
            <BackupDialogContainer onClose={onClose} />
        </div>
    </Dialog>


}

export function BackupDialogButton() {

    const [opened, setOpened] = useState(false);

    const [devToolsOpened, setDevToolsOpened] = useState(false);

    useEffect(() => {
       
        window.enableBackup = () => {
            setDevToolsOpened(true);
        }
    
    })

    return <div className="absolute bottom-1 right-1">
        { devToolsOpened && <button onClick={() => setOpened(true)} className="bg-blue-500 text-white px-4 py-2 rounded-md">Backup en herstel</button> }
        <BackupDialog opened={opened} onClose={() => setOpened(false)} />
    </div>



}