import React, { createContext, useContext, useState, useEffect } from 'react';

  
const CalendarContext = createContext();

// Create a provider component
export default function CalendarProvider({ children }) {

    const amsterdamTimezone = 'Europe/Amsterdam';
    const currentDate = new Date().toLocaleString("en-US", {timeZone: amsterdamTimezone});
    const currentYear = new Date(currentDate).getFullYear();
    const currentMonth = new Date(currentDate).getMonth();

    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [selectedMonth, setSelectedMonth] = useState(currentMonth + 1); // JavaScript months are 0-based

    const selectPreviousMonth = () => {
        if (selectedMonth === 1) {
            setSelectedYear(prevYear => prevYear - 1);
            setSelectedMonth(12);
        } else {
            setSelectedMonth(prevMonth => prevMonth - 1);
        }
    };

    const selectCurrentMonth = () => {
        setSelectedYear(currentYear);
        setSelectedMonth(currentMonth + 1);
    };

    const selectNextMonth = () => {
        if (selectedMonth === 12) {
            setSelectedYear(prevYear => prevYear + 1);
            setSelectedMonth(1);
        } else {
            setSelectedMonth(prevMonth => prevMonth + 1);
        }
    };

    return (
        <CalendarContext.Provider value={{  selectedYear, selectedMonth, selectPreviousMonth, selectCurrentMonth, selectNextMonth }}>
            {children}
        </CalendarContext.Provider>
    );
};

export const useCalendar = () => {
    return useContext(CalendarContext);
};

