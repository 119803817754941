import { CheckIcon } from "@heroicons/react/24/solid";

export default function CheckboxInput({ value, onChange, children, hasError, disabled = false }) {

    let stateClassName = value ? 'bg-cal-blue text-white border-cal-blue' : 'bg-white text-black';

    if(disabled) {
        stateClassName += ' opacity-50';
    }

    if(hasError) {
        stateClassName += ' border-red-500';
    }

    return <label className={`flex flex-row px-4 py-2 gap-4 rounded-xl justify-center items-center box-content border border-gray-300 ${stateClassName}`}>
        <input checked={value} disabled={disabled} type="checkbox" onClick={onChange} className="hidden" />
        <div className={`rounded-full bg-white p-1 border border-gray-300  ${value ? 'border-cal-blue' : ''}`}>
            <CheckIcon className={`w-5 font-bold ${value ? 'text-cal-blue' : 'text-white'}`} />
        </div>
        
        <div className="">{children}</div>
    </label>
} 