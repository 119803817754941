import { useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import nl from 'date-fns/locale/nl';
import "react-datepicker/dist/react-datepicker.css";
import { DateTime } from "luxon";


export default function ReactDatepicker (properties) {

    const { date, setDate, classes} = properties;

    registerLocale('nl', nl)


    return (
        <DatePicker
            placeholderText="Geboortedatum *"
            locale="nl"
            selected={date}
            onChange={(date) => setDate(date)}
            className={classes}
            dateFormat={"dd-MM-yyyy"}
            required
        />
    );
}