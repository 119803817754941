import { useState, useEffect } from "react";
import { TrashIcon } from "@heroicons/react/24/solid";
import CreateBackupButton from "./CreateBackupButton";


export default function BackupDialogPanel({ onClose, onFileSelect, selectedFile, onDownload, onRestoreMode, onOptionChange = () => {}, options = {} }) {

    
    return <div className={`text-sm`}>
        <span className="font-bold text-cal-blue">Backup en herstel</span>
        <div className="py-4 flex flex-col gap-2">
            <div className="">
                <AnonimizeCheckbox value={options.anonimize} onChange={(e) => onOptionChange('anonimize', e.target.checked)} />
            </div>
            <div className="">
                <IncludeUnavailableDatesCheckbox value={options.includeUnavailableDates} onChange={(e) => onOptionChange('includeUnavailableDates', e.target.checked)} />
            </div>
            <div className="">
                <IncludeSettingsCheckbox value={options.includeSettings} onChange={(e) => onOptionChange('includeSettings', e.target.checked)} />
            </div>
        </div>
        
        <div className="">
            <SelectFileInput onFileSelect={onFileSelect} selectedFile={selectedFile} />
        </div>

        { selectedFile && <table className="border-spacing-2 border-separate py-2">
            <thead>
                <tr>
                    <th>Collectie</th>
                    <th>Items</th>
                </tr>
            </thead>
            <tbody>
                {Object.keys(selectedFile).map((key) => {

                    let collection = selectedFile[key];

                    return <tr key={key}>

                        <td className="font-bold text-cal-blue">{key}</td>
                        <td>{collection.length} items</td>
                    </tr>

                })}
            </tbody>
            <tfoot>
                <tr>
                    <td colSpan="2" className="text-right">
                        <button onClick={onRestoreMode} className="bg-cal-blue text-white px-4 py-2 rounded-md ml-4">Start herstel modus</button>
                    </td>
                </tr>
            </tfoot>
        </table> }
        <div className="py-4">
            <CreateBackupButton />
        </div>
        
    </div>
}

function SelectFileInput({ onFileSelect, selectedFile }) {
    
        const [inputKey, setInputKey] = useState(Math.random().toString());

        useEffect(() => {
            if(!selectedFile) {
                console.log("there was no selected file chaning key");
                setInputKey(Math.random().toString());
            } 
            if(selectedFile) {
                console.log("there was a selected file", selectedFile);
            }
        }, [selectedFile])
    
        const onChange = (e) => {
            onFileSelect(e.target.files[0]);
        }
    
        return <div className="flex flex-row border">
                <input type="file" id="restore-input" className=" p-2 flex-1 cursor-pointer border-r" onChange={onChange} key={inputKey} />
                
                <TrashIcon className={`p-2 h-10 w-10 ${selectedFile ? 'text-red-500' : 'text-gray-300'}  cursor-pointer`} onClick={() => onFileSelect(null)} />
        </div>
    
    
}

function AnonimizeCheckbox({ value, onChange }) {

    return <label className="flex items-center cursor-pointer">
        <input type="checkbox" checked={value} onChange={onChange} />
        <span className="ml-2">Anonimiseer data</span>
    </label>

}

function IncludeUnavailableDatesCheckbox({ value, onChange }) {

    return <label className="flex items-center cursor-pointer">
        <input type="checkbox" checked={value} onChange={onChange} />
        <span className="ml-2">Onbeschikbare dagen herstellen</span>
    </label>

}

function IncludeSettingsCheckbox({ value, onChange }) {

    return <label className="flex items-center cursor-pointer">
        <input type="checkbox" checked={value} onChange={onChange} />
        <span className="ml-2">Instellingen herstellen</span>
    </label>

}