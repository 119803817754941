import {
    ChevronDownIcon,
    ChevronLeftIcon,
    ChevronRightIcon
} from '@heroicons/react/20/solid';
import { useState } from 'react';
import { useCalendar } from '../Context/CalendarContext';

function getWeekdays(year, month) {
    const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
    const amsterdamTimezone = 'Europe/Amsterdam';
    const currentDate = new Date().toLocaleString("nl-NL", {timeZone: amsterdamTimezone});
    const currentYear = new Date(currentDate).getFullYear();
    const currentMonth = new Date(currentDate).getMonth();
    const currentDay = new Date(currentDate).getDate();
    
    let startDate = new Date(year, month - 1, 1, 12);
    startDate.setDate(startDate.getDate() - (startDate.getDay() === 0 ? 6 : startDate.getDay() - 1)); // adjust to previous Monday
    let endDate = new Date(year, month, 0);
    endDate.setDate(endDate.getDate() + (endDate.getDay() === 0 ? 0 : 7 - endDate.getDay())); // adjust to next Sunday
  
    let result = [];
  
    for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
      if (weekdays.includes(weekdays[d.getUTCDay() - 1])) {
        result.push({
            month: d.toLocaleString("nl-NL", {timeZone: amsterdamTimezone, month: 'long'}),
          dayOfWeek: weekdays[d.getUTCDay() - 1],
          date: d.getUTCDate(),
          fullDate: `${String(d.getUTCDate()).padStart(2, '0')}-${String(d.getUTCMonth() + 1).padStart(2, '0')}-${d.getUTCFullYear()}`,
          isCurrentMonth: d.getMonth() === (month - 1),
          isToday: d.getFullYear() === currentYear && d.getMonth() === currentMonth && d.getDate() === currentDay
        });
      }
    }
    
    return result;
  }


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


export default function Calendar({ dayCards, onDayClick }) {

    const amsterdamTimezone = 'Europe/Amsterdam';
    const currentDate = new Date().toLocaleString("en-US", {timeZone: amsterdamTimezone});
    const currentYear = new Date(currentDate).getFullYear();
    const currentMonth = new Date(currentDate).getMonth();

    const { selectedYear, selectedMonth, selectPreviousMonth, selectCurrentMonth, selectNextMonth } = useCalendar();

    const days = getWeekdays(selectedYear || currentYear, selectedMonth).map(x => ({ ...x, events: [] }));
    const selectedDay = days.find((day) => day.isSelected)

    const monthName = (new Date(selectedYear, selectedMonth - 1, 1, 12)).toLocaleString('nl-NL', { timeZone: 'Europe/Amsterdam', month: 'long' });

    return <div>

        <div className="shadow lg:flex lg:flex-auto lg:flex-col gap-[2px] rounded-full overflow-hidden rounded-xl">
            <div>
                <div className="bg-cal-blue text-white text-xs font-semibold leading-6 lg:flex p-3">
                    
                    <div className="flex-auto">
                        <span className="text-xl capitalize">{monthName} {selectedYear}</span> 
                    </div>

                    <div className="flex flex-row justify-center">
                        <div>
                            <button onClick={selectPreviousMonth}><ChevronLeftIcon className="w-6" /></button>
                        </div>
                        <div>
                            <button onClick={selectCurrentMonth}>Vandaag</button>
                        </div>
                        <div>
                            <button onClick={selectNextMonth}><ChevronRightIcon className="w-6" /></button>
                        </div>
                    </div>
                    
                    
                </div>
                <div className="grid grid-cols-5 text-right text-xs font-semibold leading-6 text-white lg:flex-none">
                    <div className="bg-cal-blue p-2">
                        M<span className="sr-only sm:not-sr-only">a</span>
                    </div>
                    <div className="bg-cal-blue p-2">
                        D<span className="sr-only sm:not-sr-only">i</span>
                    </div>
                    <div className="bg-cal-blue p-2">
                        W<span className="sr-only sm:not-sr-only">o</span>
                    </div>
                    <div className="bg-cal-blue p-2">
                        D<span className="sr-only sm:not-sr-only">o</span>
                    </div>
                    <div className="bg-cal-blue p-2">
                        V<span className="sr-only sm:not-sr-only">r</span>
                    </div>
                </div>
            </div>
            <div className="flex bg-cal-light-gray text-xs leading-6 text-gray-700 lg:flex-auto">
                <div className=" w-full grid grid-cols-5 gap-[2px] ">
                    {days.map((day) => {

                        const dayCard = dayCards?.[day.fullDate];
                        return (<div onClick={() => onDayClick(day.fullDate)}
                            key={day.fullDate}
                            className={classNames(
                                day.isCurrentMonth ? 'bg-white' : 'bg-cal-lighter-gray text-black',
                                'relative z-10 px-3 py-2 text-right h-24 flex flex-col items-end'
                            )}
                        >   
                            <time
                                dateTime={day.fullDate}
                                className={classNames(" relative z-30",
                                    dayCard ? dayCard.dayLabelClass : undefined,
                                    day.isToday
                                        ? 'flex h-6 w-6 items-center justify-center rounded-full bg-cal-blue font-semibold text-white'
                                        : undefined
                                )
                                }
                            >
                                {day.date}
                            </time>
                            { dayCard?.component  }
                           
                        </div>)
                    })}
                </div>
               
            </div>
        </div>
    </div>

}

