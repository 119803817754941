
import { useState, useEffect } from 'react';
import CalendarAvailabilityPanel from "./CalendarAvailabilityPanel";
import { usePublicSettingsContext } from "../../Context/PublicSettingsContext";
import { formatDateFromISO, formatDateToISO, isValidDate } from 'Utils/date';
import api from "../../api/api";
import { Server } from "../../Utils/config";
import { set, setDate } from 'date-fns';



const types = {
    aanpakker: {
        name: "Aanpakker voor een dag",
        calendarEndDateExclusiveUpperBound: "aanpakkerVoorEenDagCalendarEndDateExclusiveUpperBound",
    },
    heldenavond: {
        name: "Heldenavond",
        calendarEndDateExclusiveUpperBound: "heldenavondCalendarEndDateExclusiveUpperBound",
    },
    teamuitje: {
        name: "Teamuitje",
        calendarEndDateExclusiveUpperBound: "teamuitjeCalendarEndDateExclusiveUpperBound",
    }
};

export default function CalendarAvailabilityContainer({ reload }) {

    const { publicSettings } = usePublicSettingsContext();

    const [saveLoading, setSaveLoading] = useState(false);
    const [selectedType, setSelectedType] = useState("aanpakker");
    const [dateValue, setDateValue] = useState("");
    const [dateError, setDateError] = useState(false);

    const [dateSettings, setDateSettings] = useState({
        aanpakkerVoorEenDagCalendarEndDateExclusiveUpperBound: null,
        heldenavondCalendarEndDateExclusiveUpperBound: null,
        teamuitjeCalendarEndDateExclusiveUpperBound: null,
    });

    console.log("publicSettings in render", publicSettings);
    const publicSettingsKeyValue = Object.values(publicSettings).reduce((col, x) => ({...col, [x.key]: {...x, }}), {});
    console.log("publicSettingsKeyValue in render", publicSettingsKeyValue);

    useEffect(() => {
        let settingDate = publicSettingsKeyValue?.aanpakkerVoorEenDagCalendarEndDateExclusiveUpperBound
        if(settingDate) {
            let formattedDate = formatDateFromISO(settingDate.value);
            setDateSettings((dateSettings) => ({
                ...dateSettings,
                aanpakkerVoorEenDagCalendarEndDateExclusiveUpperBound: formattedDate
            }));

            if(selectedType == "aanpakker") {
                setDateValue(formattedDate);
            }
        }
    }, [publicSettingsKeyValue?.aanpakkerVoorEenDagCalendarEndDateExclusiveUpperBound?.value])

    useEffect(() => {
        let settingDate = publicSettingsKeyValue?.heldenavondCalendarEndDateExclusiveUpperBound;
        if(settingDate) {
            let formattedDate = formatDateFromISO(settingDate.value);
            setDateSettings((dateSettings) => ({
                ...dateSettings,
                heldenavondCalendarEndDateExclusiveUpperBound: formattedDate
            }));

            if(selectedType == "heldenavond") {
                setDateValue(formattedDate);
            }

        }
    }, [publicSettingsKeyValue?.heldenavondCalendarEndDateExclusiveUpperBound?.value])

    useEffect(() => {
        let settingDate = publicSettingsKeyValue?.teamuitjeCalendarEndDateExclusiveUpperBound;
        if(settingDate) {
            let formattedDate = formatDateFromISO(settingDate.value);
            setDateSettings((dateSettings) => ({
                ...dateSettings,
                teamuitjeCalendarEndDateExclusiveUpperBound: formattedDate
            }));
            if(selectedType == "teamuitje") {
                setDateValue(formattedDate);
            }
        }
    }, [publicSettingsKeyValue?.teamuitjeCalendarEndDateExclusiveUpperBound?.value])

    const onTypeChange = (type) => {
        console.log("changing type", type);
        setSelectedType(type);
        setDateValue(dateSettings[types[type].calendarEndDateExclusiveUpperBound]);
        setDateError(false);
    }

    const onDateChange = (date) => {

        console.log("changing date", date);

        setDateValue(date);
        if(date && date.match(/^[0-9]{2}\-[0-9]{2}\-[0-9]{4}/) && isValidDate(date)) {
            setDateSettings({
                ...dateSettings,
                [types[selectedType].calendarEndDateExclusiveUpperBound]: date
            });
            setDateError(false);
        } else {
            setDateError("Ongeldige datum")
        }
        
    }
    
    const selectedDate = dateSettings[types[selectedType].calendarEndDateExclusiveUpperBound];

    const onSave = async () => {
        setSaveLoading(true);
        for(let key in dateSettings) {
            let setting = dateSettings[key];
            if(setting) {
                let documentID = publicSettingsKeyValue[key].$id;
                await api.updateDocument(Server.databaseID, Server.collections.PublicSettings, documentID, { value: formatDateToISO(setting) });
                reload({ message: "Beschikbaarheid kalender is aangepast", show:true })
            }
        }
        setSaveLoading(false);
    }

    return <CalendarAvailabilityPanel 
        onTypeChange={onTypeChange}
        onDateChange={onDateChange}
        selectedType={selectedType}
        selectedDate={selectedDate}
        dateValue={dateValue}
        dateError={dateError}
        loading={saveLoading}
        onSave={onSave}
    />

}