import { CheckIcon, MinusIcon, PlusIcon } from "@heroicons/react/24/solid";

export default function TextAreaInput({ value = "", onChange, hasError,  disabled, readOnly = false, className = "w-full box-content" }) {

    let extraClassName = '';
    if(hasError) {
        extraClassName += ' border-red-500';
    }

    return <textarea rows="3" value={value || ""} readOnly={readOnly} disabled={disabled} onChange={onChange} className={` flex flex-row px-4 py-3 rounded-xl justify-center items-center  border border-gray-300 ${extraClassName} ${className}`} />

}