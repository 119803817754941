
import { useEffect, useState } from 'react';
import { getEmails } from 'api/backend';
import { useMailchimpEmailContext } from 'Context/MailchimpEmailContext';
import { formatDateTimeFromISO } from 'Utils/date';
import { ChevronRightIcon, ChevronLeftIcon, CheckIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import TextInput from 'Components/Forms/Controls/TextInput';
import { TypeSelection } from './AdminRequestList';
import { Listbox } from '@headlessui/react'
/*

    POST   /api/:version/aanpakker-voor-een-dag-requests/:requestId/emails/confirmation
    GET    /api/:version/emails
    POST   /api/:version/heldenavond-requests/:requestId/emails/confirmation
    POST   /api/:version/teamuitje-requests/:requestId/emails/confirmation

    Email list structure
        datetime
        email
        subject
        opened
        type

*/

export default function AdminEmails() {

    const { emails, reload } = useMailchimpEmailContext();
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    const [selectedType, setSelectedType] = useState(null);
    const [selectedMailType, setSelectedMailType] = useState(null);
    const [search, setSearch] = useState("");

    let filteredEmails = emails;

    console.log("current type filter", selectedType);
    console.log("current mail type filter", selectedMailType);


    useEffect(() => {
        if(!emails || emails.length < 1) {
            reload();
        }
        
    }, [])

    if(search) {
        filteredEmails = filteredEmails.filter(email => {
            return email.recipient.toLowerCase().includes(search.toLowerCase()) || email.subject.toLowerCase().includes(search.toLowerCase());
        });
    }

    if(selectedType && selectedType !== "all") {
        filteredEmails = filteredEmails.filter(email => {
            if(selectedType === 'aanpakker') {
                return email?.metadata?.requestType === 'aanpakker-voor-een-dag';
            } else {
                return email?.metadata?.requestType === selectedType;
            }
        });
    }

    if(selectedMailType) {
        filteredEmails = filteredEmails.filter(email => {
            return email?.templateName === selectedMailType;
        });
    }


    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    let filteredEmailList = filteredEmails.slice(indexOfFirstItem, indexOfLastItem);


   


    return <div className="lg:w-[70vw] mx-auto lg:p-5 flex flex-col gap-8">
        <h1 className="text-2xl font-bold">Admin Emails</h1>
        <div className="">
            <EmailFilters 
                selectedType={selectedType} 
                setSelectedType={setSelectedType}
                selectedMailType={selectedMailType} 
                setSelectedMailType={setSelectedMailType}
                search={search}
                setSearch={setSearch}
                />
        </div>
        <div className="">
            <AdminEmailsTable emails={filteredEmailList} />
        </div>
        
        <Pagination
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            filteredItems={filteredEmails}
            indexOfFirstItem={indexOfFirstItem}
            indexOfLastItem={indexOfLastItem}
            setItemsPerPage={setItemsPerPage}
            currentItems={filteredEmails} />

    </div>


}

function PaginationResultsPerPage({ itemsPerPage, setItemsPerPage }) {

    const options = {
        10: "10",
        50: "50",
        100: "100"
    }

    return (
        <Listbox value={itemsPerPage} onChange={setItemsPerPage} className="">
            <div className="relative">
                <Listbox.Button className="px-4 py-2 bg-white rounded-xl text-gray-600 flex flex-row gap-4 border border-gray-300 items-center">
                    <div>{options[itemsPerPage]}</div>
                    <div><ChevronDownIcon className="w-4" /></div>
                </Listbox.Button>
            
                <Listbox.Options className="bg-white px-4 py-3  flex flex-col absolute left-0 shadow-md mt-2 w-full ui-open:z-10 ">
                    {Object.keys(options).map((status) => (
                        <Listbox.Option
                            key={status}
                            value={status}
                        >
                            {({ selected, active }) => <div className="flex flex-row gap-2 py-2 items-center whitespace-nowrap cursor-pointer  "><div> {options[status]}</div><div>{selected && <CheckIcon className="w-4" />} </div></div>}
                        
                        </Listbox.Option>
                    ))}
                </Listbox.Options>
            </div>
        </Listbox>
    )

}

function Pagination({ itemsPerPage, setItemsPerPage, currentPage, setCurrentPage, filteredItems, indexOfFirstItem, indexOfLastItem, currentItems }) {
    return <div className="flex flex-row justify-between items-center mt-8">
        <div>
            <PaginationResultsPerPage itemsPerPage={itemsPerPage} setItemsPerPage={setItemsPerPage} />
        </div>
        <div>
            <label>Resultaten</label>
            <span className="ml-2">{indexOfFirstItem + 1}</span>
            <span className="mx-2">tot</span>
            <span className="">{Math.min(indexOfLastItem, filteredItems.length)}</span>
            <span className="mx-2">van de</span>
            <span className="">{filteredItems.length} resultaten</span>

        </div>
        <div className="flex flex-row gap-4">
            <button className="text-gray-700 disabled:text-gray-400 bg-gray-100 py-2 px-4 border border-gray-300 rounded-md" onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage == 1}>
                <div className="flex flex-row gap-2 items-center">
                    <div><ChevronLeftIcon className="w-4" /></div>
                    <div>Vorige</div>
                </div>
            </button>
            <button className="text-gray-700 disabled:text-gray-400 bg-gray-100 py-2 px-4 border border-gray-300 rounded-md" onClick={() => setCurrentPage(currentPage + 1)} disabled={currentItems.length < itemsPerPage * currentPage}>
                <div className="flex flex-row gap-2 items-center">
                    <div>Volgende</div>
                    <div> <ChevronRightIcon className="w-4" /></div>
                </div>
            </button>
        </div>
    </div>

}


const mailTypes = [
    { label: "Alle mail typen", value: null },
    { label: "Aanpakker voor een dag bevestiging", value: "aanpakker-voor-een-dag-request-confirmation" },
    { label: "Aanpakker voor een dag bedankt", value: "aanpakker-voor-een-dag-thanks" },
    { label: "Heldenavond herinnering", value: "heldenavond-reminder" },
    { label: "Heldenavond bevestiging", value: "heldenavond-request-confirmation" },
    { label: "Teamuitje bevestiging", value: "teamuitje-request-confirmation" },
    { label: "Teamuitje herinnering", value: "teamuitje-reminder" },
    { label: "Teamuitje bedankt", value: "teamuitje-thanks" },
]

function MailTypeSelection({ onSelectType, currentType, defaultLabel="Alle mail typen"}) {

    console.log("currentType", currentType);


    return <Listbox value={currentType} onChange={onSelectType} className="">

        <div className="relative">
            <Listbox.Button className="px-4 py-3 bg-white rounded-xl text-gray-600 flex flex-row gap-4 border border-gray-300 items-center">
                <div>{currentType ? mailTypes.find(type => type.value === currentType).label : defaultLabel}</div>
                <div><ChevronDownIcon className="w-4" /></div>
            </Listbox.Button>
        
            <Listbox.Options className="bg-white px-4 py-3  flex flex-col absolute right-0 shadow-md mt-2 w-full min-w-fit ui-open:z-10 ">
                {mailTypes.map((type) => (
                    <Listbox.Option
                        key={type.value}
                        value={type.value}
                    >
                        {({ selected, active }) => <div className="flex flex-row gap-2 py-2 items-center whitespace-nowrap cursor-pointer  "><div> {type.label}</div><div>{selected && <CheckIcon className="w-4" />} </div></div>}
                    
                    </Listbox.Option>
                ))}
            </Listbox.Options>
        </div>
    </Listbox>

}

function EmailFilters({ 
    selectedType, setSelectedType,
    selectedMailType, setSelectedMailType,
    search, setSearch
}) {

    

    return <div className="flex justify-between">
        <div className="flex gap-4 items-center">
            <div>Zoek</div>
            <TextInput value={search} onChange={(e) => setSearch(e.target.value)} />
        </div>
        <div className="flex gap-4 items-center">
            <div>Type uitje</div>
            <TypeSelection 
                defaultLabel="alle typen" 
                onSelectType={(type) => setSelectedType(type)} 
                currentType={selectedType} />
        </div>
        <div className="flex gap-4 items-center">
            <div>Type mail</div>
            <MailTypeSelection 
                defaultLabel="alle emails" 
                onSelectType={(type) => setSelectedMailType(type)} 
                currentType={selectedMailType} />
        </div>
    </div>

}

const printEmailDate = (email) => {
    return formatDateTimeFromISO(email.submissionTime);
}

const printEmail = (email) => {
    return email.recipient;
}

const printSubject = (email) => {
    return email.subject;
}

const printStatus = (email) => {
    return email.status;
}

const printOpened = (email) => {
    return email?.opens?.length > 0 ? <CheckIcon className="text-green-500 w-8 h-8"/> : "-";
}


function AdminEmailsTable({ emails }) {
    return <table className="w-full bg-white pt-12 rounded-xl shadow-md border border-gray-200">
        <thead>
            <tr>
                <td colSpan="5" className="px-4 py-4 border-y border-gray-200"></td>
            </tr>
            <tr>
                <th className="bg-gray-100/70 px-4 py-2 border-y border-gray-200 pl-8 text-left">Datum / tijd</th>
                <th className="bg-gray-100/70 px-4 py-2 border-y border-gray-200 pl-8 text-left">E-mail</th>
                <th className="bg-gray-100/70 px-4 py-2 border-y border-gray-200 pl-8 text-left">Mail onderwerp</th>
                <th className="bg-gray-100/70 px-4 py-2 border-y border-gray-200 pl-8 text-left">Status</th>
                <th className="bg-gray-100/70 px-4 py-2 border-y border-gray-200 pl-8 text-left">Geopend</th>
            </tr>
        </thead>
        <tbody>
            {emails.map((email, index) => {
                return <tr key={index}>
                    <td className="px-4 py-2 pl-8 text-left">{printEmailDate(email)}</td>
                    <td className="px-4 py-2 pl-8 text-left">{printEmail(email)}</td>
                    <td className="px-4 py-2 pl-8 text-left">{printSubject(email)}</td>
                    <td className="px-4 py-2 pl-8 text-left">{printStatus(email)}</td>
                    <td className="px-4 py-2 pl-8 text-left">{printOpened(email)}</td>
                </tr>
            })}
        </tbody>
    </table>

}
