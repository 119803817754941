const { DateTime } = require("luxon");


const colorsAddjectives = [
    'Gouden',
    'Zilveren',
    'Bronzen',
    'Koperen',
    'Platinum',
    'Gele',
    'Rode',
    'Roze',
    'Blauwe',
    'Groene',
    'Grijze',
    'Witte',
    'Zwarte',
]

const streetTypes = [
    'Straat',
    'Laan',
    'Weg',
    'Pad',
    'Plein',
    'Hof',
    'Singel',
    'Gracht',
    'Kade',
    'Dreef',
    'Boulevard'
]

const guildTypes = [
    'Bakker',
    'Slager',
    'Groenteboer',
    'Bloemist',
    'Kapper',
    'Schoenmaker',
    'Kledingmaker',
    'Timmerman',
    'Schilder',
    'Metselaar',
    'Stukadoor',
    'Loodgieter',
    'Elektricien',
    'Fietsenmaker',
    'Autodealer',
    'Makelaar',
    'Advocaat',
    'Notaris',
    'Dokter',
]

const maleFirstNames = [
    'Jan',
    'Piet',
    'Klaas',
    'Henk',
    'Bert',
    'Erik',
    'Joris',
    'Willem',
    'Hans',
    'Johan',
];

const femaleFirstNames = [
    'Jannie',
    'Pietje',
    'Klaasje',
    'Hennie',
    'Berta',
    'Erika',
    'Joriska',
    'Willemijn',
    'Hanna',
    'Johanna',
];

const placeNames = [
    'Amsterdam',
    'Rotterdam',
    'Utrecht',
    'Den Haag',
    'Groningen',
    'Maastricht',
    'Leeuwarden',
    'Arnhem',
    'Nijmegen',
    'Enschede',
    'Zwolle',
    'Almere',
    'Lelystad',
    'Assen',
    'Apeldoorn',
    'Eindhoven',
    'Tilburg',
    'Breda',
    'Middelburg',
    'Vlissingen',
    'Haarlem',
    'Leiden',
    'Delft',
    'Dordrecht',
    'Amersfoort',
    'Alkmaar',
    'Hoorn',
    'Zaandam',
    'Purmerend',
    'Hoofddorp',
    'Heerlen',
    'Sittard',
    'Roermond',
    'Venlo',
    'Venray',
    'Weert',
    'Roosendaal',
    'Bergen op Zoom',
    'Oss',
    'Den Bosch',
    'Tilburg',
    'Breda',
    'Ede',
    'Veenendaal',
    'Doetinchem',
    'Zutphen',
    'Deventer',
    'Zwolle',
    'Kampen',
    'Meppel',
    'Emmen',
]



export function getRandomFakeLastName() {
    let insertion = "de ";
    let lastName = insertion + colorsAddjectives[Math.floor(Math.random() * colorsAddjectives.length)];
    lastName += " ";
    lastName += guildTypes[Math.floor(Math.random() * guildTypes.length)];
    return lastName;
}

export function getRandomFakeFirstName (isMale) {
    if(isMale) {
        return maleFirstNames[Math.floor(Math.random() * maleFirstNames.length)];
    }
    return femaleFirstNames[Math.floor(Math.random() * femaleFirstNames.length)];
}

export function getFakeOrganizationName() {
    return colorsAddjectives[Math.floor(Math.random() * colorsAddjectives.length)] + " " + guildTypes[Math.floor(Math.random() * guildTypes.length)] + " B.V.";
}

export function getRandomFakeEmail(firstName, lastName) {
    let email = firstName.toLowerCase() + "." + lastName.toLowerCase() + "@localhost";
    email = email.replace(/ /g, '.');
    return email;
}

export function getFakeBirthDate(minimumAge = 18) {
    const date = DateTime.now();
    const randomAge = Math.floor(Math.random() * 60) + minimumAge;
    const birthDate = date.minus({ years: randomAge });
    return birthDate.toISODate();
}

export function getFakeAddress() {
    let number = Math.floor(Math.random() * 100);
    let street = guildTypes[Math.floor(Math.random() * guildTypes.length)] + streetTypes[Math.floor(Math.random() * streetTypes.length)];

    let place = placeNames[Math.floor(Math.random() * placeNames.length)];
    let zipcode = Math.floor(Math.random() * 10000) + "AA";
    return {
        number,
        street,
        place,
        zipcode
    };
}