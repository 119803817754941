
import { useState } from "react";
import BackupDialogPanel from "./BackupDialogPanel";
import BackupProvider, { useBackupContext } from "Context/BackupContext";
import JSZip from "jszip";
import { Server } from "Utils/config";


export default function BackupDialogContainer({ onClose }) {

    const { setBackup, setBackupOptions } = useBackupContext();
    const [selectedFile, setSelectedFile] = useState(null);
    const [options, setOptions] = useState({ anonimize: false });

    const onFileSelect = async (file) => {

        if(!file) {
            setSelectedFile(null);
            return;
        }

        const collections = Server.collections;
		const reversedCollection = {};
		Object.keys(collections).forEach((key) => {
			reversedCollection[collections[key]] = key;
		});

        let sourceFile = file;
        let zip = await JSZip.loadAsync(sourceFile);

        let filteredFiles = {};

		for(let i in zip.files) {
			let file = zip.files[i];
			if(file.dir) continue;



			const path = file.name.split('/');
			const collection = reversedCollection[path[1]];
			if(collection) {
				if(!filteredFiles[collection]) {
					filteredFiles[collection] = [];
				}

				let fileIsJson = file.name.endsWith('.json');
				if(fileIsJson) {
					let content = await file.async('string');
					const json = JSON.parse(content);
					filteredFiles[collection].push(json);
				}
			}

		}


        setSelectedFile(filteredFiles);
    }

    const onRestoreMode = () => {

        console.log("on restore mode", options);

        setBackupOptions(options);
        setBackup(selectedFile, options);
        
        onClose();
    }

    const onDownload = () => {

    }

    const onOptionChange = (key, value) => {
        setOptions({ ...options, [key]: value });
    }

    return <BackupDialogPanel  
        options={options}
        onOptionChange={onOptionChange}
        onRestoreMode={onRestoreMode}
        selectedFile={selectedFile}
        onDownload={() => {}}
        onFileSelect={onFileSelect}
        onClose={onClose}  />

}
