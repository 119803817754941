import { useBackupContext } from './BackupContext';
import { useCalendarEventContext } from './CalendarEventContext';

export function useCalendarEventContextSelector() {
	const { backup, backupMode } = useBackupContext();
	const liveData = useCalendarEventContext();
	if(backupMode) {
		return backup;
	} else {
		return liveData;
	}
}