import { useState, useEffect } from "react";
import FormHeldenAvondPanel from "../../../Components/Forms/FormHeldenAvondPanel";
import { addHeldenAvondAction, updateHeldenAvondAction, validateHeldenAvondForm, createHeldenAvondFormData } from "../Actions/Heldenavond";

export default function FormHeldenAvondContainer({ selectedDay, event, reload, onClose }) {

    const [heldenAvondFormData, setHeldenAvondFormData] = useState({ sendEmails: false  });
    const [heldenAvondErrors, setHeldenAvondErrors] = useState({});
	const [loading, setLoading] = useState(false);

    useEffect(() => {
		setHeldenAvondFormData(createHeldenAvondFormData(event));
    }, [event])

	const updateHeldenAvond = async (heldenAvondFormData) => {
		setLoading(true);
		try {
			await updateHeldenAvondAction(heldenAvondFormData, event);
			setLoading(false);
			onClose({ message: "Heldenavond is aangepast" });
			reload();
		} catch (err) {
			console.log(err);
			setLoading(false);
		}
	}

    const addHeldenAvond = async (heldenAvondFormData) => {
		setLoading(true);
		try {
			await addHeldenAvondAction(heldenAvondFormData, selectedDay);
			setLoading(false);
			onClose({ message: 'Heldenavond is aangemaakt.' });
			reload();
		} catch (err) {
			console.log(err);
			setLoading(false);
		}
	}

	const validate = (heldenAvondFormData) => {
		let { errors } = validateHeldenAvondForm(heldenAvondFormData);
		setHeldenAvondErrors(errors);
		return Object.values(errors).some((error) => error);
	}

	const saveHeldenAvond = async () => {

		if(!validate(heldenAvondFormData)) {

			if(event?.data?.mainRequest?.$id) {
				return updateHeldenAvond(heldenAvondFormData);
			} else {
				return addHeldenAvond(heldenAvondFormData);
			}
		}
	}


    return <FormHeldenAvondPanel 
		loading={loading}
		errors={heldenAvondErrors} 
		selectedDay={selectedDay} 
		onSave={saveHeldenAvond} 
		heldenAvond={heldenAvondFormData} 
		setHeldenAvond={setHeldenAvondFormData} />


}
