import React, { createContext, useContext, useState, useEffect } from 'react';
import { getEmails } from 'api/backend';
import { LoadingOverlay } from '../Components/LoadingOverlay';

  
const MailchimpEmailContext = createContext();

// Create a provider component
export default function MailchimpEmailProvider({ children }) {

    const [emails, setEmails] = useState([]);
    const [loading, setLoading] = useState(true);

    const reload = async () => {
        setLoading(true);
        try {
            const emails = await getEmails();
            setEmails(emails);
        } catch(err) {
            console.error("error loading emails from mailchimp")
            console.error(err);
        } finally {
            setLoading(false);
        }
        
    }

    useEffect(() => {
       reload();
    }, []);


    return (
        <MailchimpEmailContext.Provider value={{ emails, reload }}>
           <LoadingOverlay loading={loading} />
            {children}
        </MailchimpEmailContext.Provider>
    );
};

export const useMailchimpEmailContext = () => {
    return useContext(MailchimpEmailContext);
};

