import { useState, useEffect } from "react";

import FormAanpakkerVoorEenDagPanel from "../FormAanpakkerVoorEenDagPanel";
import { updateAanpakkerVoorEenDagAction, addAanpakkerVoorEenDagAction, validateAanpakkerVoorEenDagForm, createAanpakkerVoorEenDagFormData } from "../Actions/Aanpakker";


export default function FormAanpakkerVoorEenDagContainer({ selectedDay, event, reload, onClose }) {

    const [aanpakkerVoorEenDagFormData, setAanpakkerVoorEenDagFormData] = useState({ sendEmails: false, participants: [{}] });
    const [aanpakkerVoorEenDagErrors, setAanpakkerVoorEenDagErrors] = useState([]);
	const [loading, setLoading] = useState(false);

    useEffect(() => {
		let aanpakkerVoorEenDagFormData = createAanpakkerVoorEenDagFormData(event);
		if(aanpakkerVoorEenDagFormData) {
			setAanpakkerVoorEenDagFormData(aanpakkerVoorEenDagFormData);
		}
    }, [event])

	const updateAanpakkerVoorEenDag = async (aanpakkerVoorEenDagFormData) => {
		setLoading(true);
		try {
			await updateAanpakkerVoorEenDagAction(aanpakkerVoorEenDagFormData, event);
			setLoading(false);
			onClose({ message: 'Aanpakker voor een dag is aangepast' });
			reload();
		
		} catch (err) {
			setLoading(false);
			console.log(err);
		}
	}

    const addAanpakkerVoorEenDag = async (aanpakkerVoorEenDag) => {
		console.log("aanpakker voor een dag form submit");
		setLoading(true);
		try {
			await addAanpakkerVoorEenDagAction(aanpakkerVoorEenDag, selectedDay, aanpakkerVoorEenDag);
			reload();
			setLoading(false);
			onClose({ message: 'Aanpakker voor een dag is aangemaakt' });
		} catch (err) {
			console.log(err);
			setLoading(false);
		}
	}

	

	const validate = (aanpakkerVoorEenDagFormData) => {
		const { errors, foundErrors } = validateAanpakkerVoorEenDagForm(aanpakkerVoorEenDagFormData);
		setAanpakkerVoorEenDagErrors(errors);
		return foundErrors;
	}

	const saveAanpakkerVoorEenDag = async () => {

		if(!validate(aanpakkerVoorEenDagFormData)) {

			if(event?.data?.mainRequest?.$id) {
				return updateAanpakkerVoorEenDag(aanpakkerVoorEenDagFormData);
			} else {
				return addAanpakkerVoorEenDag(aanpakkerVoorEenDagFormData);
			}
		}
	}


    return <FormAanpakkerVoorEenDagPanel 
        errors={aanpakkerVoorEenDagErrors} 
        selectedDay={selectedDay} 
		loading={loading}
        onSave={saveAanpakkerVoorEenDag} 
        aanpakkerVoorEenDag={aanpakkerVoorEenDagFormData} 
        setAanpakkerVoorEenDag={setAanpakkerVoorEenDagFormData} />


}
