import api from "../api/api";
// import { Server } from "../utils/config";
import { useEffect, useReducer } from "react";
import { Server } from "../Utils/config";
import { Query, Client } from "appwrite";


const AbstractListHook = (collectionName, stale) => {

    const reducer = (state, action) => {
        switch (action.type) {
            case FetchState.FETCH_INIT:
                return { ...state, isLoading: true, isError: false };
            case FetchState.REALTIME_CREATE:
            case FetchState.REALTIME_UPDATE:
                let updatedList = new Map(state[collectionName + "List"]?.entries())
                updatedList.set(action.payload.$id, action.payload);
                return {
                    ...state,
                    [collectionName + "List"]: updatedList,
                }
            case FetchState.REALTIME_DELETE:
                let listWithoutDeleted = new Map(state[collectionName + "List"]?.entries())
                listWithoutDeleted.delete(action.payload.$id, action.payload);
                return {
                    ...state,
                    [collectionName + "List"]: listWithoutDeleted,
                }

            case FetchState.FETCH_SUCCESS:
                let currentList = new Map(state[collectionName + "List"]?.entries()) ;
                action.payload.forEach((item) => {
                    currentList.set(item.$id, item);
                });
                
                let latestRecordDate = null;
                currentList.forEach((item) => {
                    let created = item.$createdAt;
                    let updated = item.$updatedAt;
                    if(created && (!latestRecordDate || created > latestRecordDate)) {
                        latestRecordDate = created;
                    }
                    if(updated && (!latestRecordDate || updated > latestRecordDate)) {
                        latestRecordDate = updated;
                    }
                })

                return {
                    ...state,
                    isLoading: false,
                    isError: false,
                    [collectionName + "List"]: currentList,
                    latestRecordDate: latestRecordDate
                };
            case FetchState.FETCH_FAILURE:
                return { ...state, isLoading: false, isError: true };
            default:
                throw new Error();
        }
    };

    const [state, dispatch] = useReducer(reducer, {
        isLoading: false,
        isError: false,
        [collectionName + "List"]: new Map(),
        latestRecordDate: null,
    });

    useEffect(() => {
        let didCancel = false;
        const getList = async () => {
            dispatch({ type: FetchState.FETCH_INIT });
            try {
                const data = await api.listDocuments(Server.databaseID, Server.collections[collectionName]);
                if (!didCancel) {
                    dispatch({ type: FetchState.FETCH_SUCCESS, payload: data.documents });
                }
            } catch (e) {
                if (!didCancel) {
                    dispatch({ type: FetchState.FETCH_FAILURE });
                }
            }
        };
        getList();
        return () => (didCancel = true);
    }, [stale]);

    useEffect(() => {

        // console.log("MOUNTING AbstractList Hook");

        const client = new Client();
        client
            .setEndpoint(Server.endpoint)
            .setProject(Server.project);
        
        const collectionID = Server.collections[collectionName];

        const unsubscribeUpdate = client.subscribe(`databases.${Server.databaseID}.collections.${collectionID}.documents`, async (event) => {
            // console.log("subscription update event", event);
            if(event.events.indexOf("databases.*.collections.*.documents.*.delete")) {
                dispatch({ type: FetchState.REALTIME_DELETE, payload: event.payload });
            }
            if(event.events.indexOf("databases.*.collections.*.documents.*.update")) {
                dispatch({ type: FetchState.REALTIME_UPDATE, payload: event.payload });
            }
            if(event.events.indexOf("databases.*.collections.*.documents.*.create")) {
                dispatch({ type: FetchState.REALTIME_CREATE, payload: event.payload });
            }
        });

        return () => {
            unsubscribeUpdate();
        }

    }, [])

    // console.log("state", state);

    return [state];
}

export const useGetAanpakkerVoorEenDagRequestList = (stale) => {
    return AbstractListHook("AanpakkerVoorEenDagRequest", stale);
};

export const useGetAanpakkerVoorEenDagRequestDetailsList = (stale) => {
    return AbstractListHook("AanpakkerVoorEenDagRequestDetails", stale);
};

export const useGetAanpakkerVoorEenDagRequestParticipantList = (stale) => {
    return AbstractListHook("AanpakkerVoorEenDagRequestParticipant", stale);
};

export const useGetUnavailableDateList = (stale) => {
    return AbstractListHook("UnavailableDate", stale);
};

export const useGetHeldenavondRequestList = (stale) => {
    return AbstractListHook("HeldenavondRequest", stale);
};

export const useGetTeamuitjeRequestList = (stale) => {
    return AbstractListHook("TeamuitjeRequest", stale);
};

export const useGetHeldenavondRequestDetailsList = (stale) => {
    return AbstractListHook("HeldenavondRequestDetails", stale);
};

export const useGetTeamuitjeRequestDetailsList = (stale) => {
    return AbstractListHook("TeamuitjeRequestDetails", stale);
};

export const useGetPublicSettings = () => {
    return AbstractListHook("PublicSettings", false);
}

export const FetchState = {
    FETCH_INIT: 0,
    FETCH_SUCCESS: 1,
    FETCH_FAILURE: 2,
};

export const useGetUser = () => {
    const reducer = (state, action) => {
        switch (action.type) {
            case FetchState.FETCH_INIT:
                return { ...state, isLoading: true, isError: false };
            case FetchState.FETCH_SUCCESS:
                return {
                    ...state,
                    isLoading: false,
                    isError: false,
                    user: action.payload,
                };
            case FetchState.FETCH_FAILURE:
                return { ...state, isLoading: false, isError: true };
            default:
                throw new Error();
        }
    };

    const [state, dispatch] = useReducer(reducer, {
        isLoading: false,
        isError: true,
        data: [],
    });

    useEffect(() => {
        let didCancel = false;
        const getTodos = async () => {
            dispatch({ type: FetchState.FETCH_INIT });
            try {
                const account = await api.getAccount();
                if (!didCancel) {
                    dispatch({ type: FetchState.FETCH_SUCCESS, payload: account });
                }
            } catch (e) {
                if (!didCancel) {
                    dispatch({ type: FetchState.FETCH_FAILURE });
                }
            }
        };
        getTodos();
        return () => (didCancel = true);
    }, []);

    return [state, dispatch];
};