
export default function ToggleInput({ value, onChange, id = Math.random().toString(), isPartialActive = false}) {
	return <div className="flex items-center">
		<div className={`form-switch ${isPartialActive ? 'partial' : ''}`}>
			<input type="checkbox" id={id} className="sr-only" checked={value} onChange={() => onChange((x) => !x)} />
			<label className={`bg-slate-400`} htmlFor={id}>
				<span className="bg-white shadow-sm" aria-hidden="true"></span>
				<span className="sr-only">Switch label</span>
			</label>
		</div>
	</div>
}