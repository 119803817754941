import { useState } from 'react';
import { FetchState } from '../Hooks';
import api from '../api/api';

const projectID = "648c6fafddab8d5f54c0";

const databaseID = "648c7f1fe7cf8ca15b08"

export default function Login({ dispatch }) {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);

    const handleLogin = async (e) => {
        e.preventDefault();
        dispatch({ type: FetchState.FETCH_INIT });
        try {
            await api.createSession(email, password);
            const data = await api.getAccount();
            dispatch({ type: FetchState.FETCH_SUCCESS, payload: data });
        } catch (e) {
            setError(e.message);
            dispatch({ type: FetchState.FETCH_FAILURE });
        }
    }

    return <div className="flex h-screen w-screen">
        <div className=" w-1/3 flex items-center justify-center px-16">
            <div className="w-full flex flex-col gap-4">
                <h1 className="text-3xl text-blue-500 text-center">Jarige job admin</h1>
                { error ? <div className="bg-red-500 text-white text-center p-2 rounded-xl">{error}</div> : null }
                <div className="flex flex-col gap-2">
                    <label className="text-sm text-gray-700">Email</label>
                    <input value={email} onChange={(e) => setEmail(e.target.value)} className="border border-gray-300 px-3 py-3 text-sm rounded-xl" type="text" placeholder="Email" />
                    <label className="text-sm text-gray-700">Password</label>
                    <input value={password} onChange={(e) => setPassword(e.target.value)} className="border border-gray-300 px-3 py-3 text-sm rounded-xl" type="password" placeholder="Password" />
                    <div className="flex justify-end">
                        <button onClick={handleLogin} className="bg-blue-500 text-white  py-3 rounded-xl px-8">Login</button>
                    </div>
                </div>
            </div>
        </div>
        <div className="bg-blue-500 h-full flex-1"><div></div></div>
    </div>
}