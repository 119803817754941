import React, { createContext, useContext, useState, useEffect } from 'react';


const ToastNotficationContext = createContext();

// Create a provider component
export default function ToastNotficationProvider({ children }) {

    const [toastData, setToastData] = useState({ show: false, message: '' });

    useEffect(() => {

        if (toastData.message && toastData.show) {
            setTimeout(() => {
                setToastData((d) => ({...d, show: false}));
            }, toastData.duration || 3000);
        }

    }, [toastData.show])

    return (
        <ToastNotficationContext.Provider value={{ setToastData, toastData }}>
            {children}

            <ToastNotification />

        </ToastNotficationContext.Provider>
    );
};

export const useToastNotficationContext = () => {
    return useContext(ToastNotficationContext);
};


function ToastNotification() {
    const { toastData } = useToastNotficationContext();
    const { message, type, show } = toastData;
    const [showToast, setShowToast] = useState(false);
    const [translateValue, setTranslateValue] = useState(message ? 'translate-x-0' : 'translate-x-full');

    useEffect(() => {
        if (show) {
            setShowToast(true);
            setTranslateValue('translate-x-0');
        } else {
            setTranslateValue('translate-x-[100vw]');
            setTimeout(() => {
                setShowToast(false);
            }, 300);

        }
    }, [show]);

    let className = "bg-green-600 text-white";
    if (type === 'error') {
        className = "bg-red-500 text-white";
    }

    // if (!showToast) return null;

    return (
        <div className={`fixed top-24 right-8 z-20 transition-transform duration-300 ease-in-out ${translateValue} ${className}`}>
            <div className="p-4">
                {message}
            </div>
        </div>
    );
}

